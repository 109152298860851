import axios, { AxiosRequestConfig } from 'axios';
import { PRE_LOGIN_CONFIG } from './utils/constants';
import FalconError, { E_CONFIG_ERROR } from './falconeer/FalconErrors';
import { PROVIDER_WEB_APP_ID, CURRENT_APP_VERSION } from './api/types';

class PreLoginConfiguration {
  public config: any | null = null;

  public async load(): Promise<any> {
    const options: AxiosRequestConfig<any> = {
      method: 'GET',
      url: `${PRE_LOGIN_CONFIG}?platformId=${PROVIDER_WEB_APP_ID}&platformVersion=${CURRENT_APP_VERSION}`,
    };
    const response = await axios.request(options).catch(err => {
      throw new FalconError(E_CONFIG_ERROR);
    });
    this.config = response?.data.data.configs;
    return this.config;
  }
}

const preLoginConfiguration = new PreLoginConfiguration();

export default preLoginConfiguration;
