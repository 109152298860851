import { HealthDataRequest, SummaryType, HealthDataReportRequest } from './types';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import FalconError, { E_CONFIG_MISSING, E_INVALID_TOKEN } from '../falconeer/FalconErrors';
//import postLoginConfiguration from '../PostLoginConfiguration';
import { POST_LOGIN_CONFIG_KEY_HEALTHDATA_URL } from '../utils/AppConfigKeys';
import { getAppConfigValue } from '../utils/Utils';

type QueryParameters = {
  measures: string;
  patientId: string;
  startDate: string;
  endDate: string;
  summ: SummaryType;
  limit: number;
  pageSize: number;
  pageNumber: number;
};
export type DeviceDetailInput = {
  name: string;
  value: string;
  valueType: string;
};

export type DeviceInput = {
  name?: string;
  values?: Array<DeviceDetailInput>;
};
type Tag = {
  name: string;
  value: string;
};
type MeasurementValue =
  | {
      name: string;
      measureType: 'SINGLE';
      time: Date;
      tags?: Tag[];
      value: string;
      type: string;
    }
  | {
      name: string;
      measureType: 'MULTI';
      time: Date;
      tags?: Tag[];
      values: {
        name: string;
        value: string;
        type: 'DOUBLE';
      }[];
    };
export type PatientDataDeviceRequest = {
  measurements: MeasurementValue[];
  patientId: string;
};
class HealthDataAPIClient {
  constructor(private getToken: (refreshToken: boolean) => Promise<any>) {}

  private getStartDate = (HealthDataRequest: HealthDataRequest) => {
    if (HealthDataRequest.startDate) {
      if (!isValidDate(HealthDataRequest.startDate)) {
        throw new Error('Invalid Start Date');
      } else {
        return HealthDataRequest.startDate.toISOString();
      }
    }

    let startDate = new Date();
    if (HealthDataRequest.summary === SummaryType.daily) {
      startDate.setDate(startDate.getDate() - 7);
      return startDate.toISOString();
    }

    if (HealthDataRequest.summary === SummaryType.monthly) {
      let noOfDays: number = daysInPreviousMonth(startDate.getMonth(), startDate.getFullYear());
      startDate.setDate(startDate.getDate() - noOfDays);
      return startDate.toISOString();
    }

    startDate.setDate(startDate.getDate() - 1);

    return startDate.toISOString();
  };

  private getEndDate = (HealthDataRequest: HealthDataRequest) => {
    if (HealthDataRequest.endDate) {
      if (!isValidDate(HealthDataRequest.endDate)) {
        throw new Error('Invalid End date');
      } else {
        return HealthDataRequest.endDate.toISOString();
      }
    }

    let endDate = new Date();
    endDate.setDate(endDate.getDate());

    return endDate.toISOString();
  };
  private async callHealthDataApi(request: AxiosRequestConfig<any>): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      axios
        .request(request)
        .then(response => {
          if (response.status == 200 || response.status == 201) {
            resolve(response);
          } else {
            reject('No records found');
          }
        })
        .catch(async err => {
          reject(err.response);
        });
    });
  }

  public async getPatientHealthData(request: HealthDataRequest): Promise<AxiosResponse> {
    if (request.measurementNames.length === 0) {
      throw new Error('Measurements not specified.');
    }

    if (!request.patientId) {
      throw new Error('Patient Id not specified.');
    }

    const startDate = this.getStartDate(request);
    const endDate = this.getEndDate(request);

    let queryParams: QueryParameters = {
      measures: request.measurementNames.join(','),
      patientId: request.patientId,
      startDate,
      endDate,
      summ: request.summary ? request.summary : SummaryType.raw,
      limit: request.limit ? request.limit : 100,
      pageSize: 100,
      pageNumber: 1,
    };

    let token = await this.getToken(false);
    const options: AxiosRequestConfig<any> = {
      method: 'GET',
      url: getAppConfigValue(POST_LOGIN_CONFIG_KEY_HEALTHDATA_URL),
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: queryParams,
    };
    try {
      return await this.callHealthDataApi(options);
    } catch (error: any) {
      if (error.error && error.error.errorCode != E_INVALID_TOKEN) {
        throw error;
      } else if (error.errorCode === E_CONFIG_MISSING) {
        throw new FalconError(E_CONFIG_MISSING);
      }
      token = await this.getToken(true);
      if (options.headers) options.headers.Authorization = `Bearer ${token}`;
      return await this.callHealthDataApi(options);
    }
  }

  public async getPatientReportData(HealthDataRequest: HealthDataReportRequest): Promise<AxiosResponse> {
    if (!HealthDataRequest.patientId) {
      throw new Error('Patient Id not specified.');
    }

    const startDate = this.getStartDate(HealthDataRequest);
    const endDate = this.getEndDate(HealthDataRequest);

    let token = await this.getToken(false);

    const url = getAppConfigValue(POST_LOGIN_CONFIG_KEY_HEALTHDATA_URL);

    const options: AxiosRequestConfig<any> = {
      method: 'POST',
      url: `${url}/copy-data`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        patientId: HealthDataRequest.patientId,
        startDate,
        endDate,
        isGetPatientReportedEvents: HealthDataRequest.isGetPatientReportedEvents,
        isGetAlerts: HealthDataRequest.isGetAlerts,
        isPreviousDataNeeded: HealthDataRequest.isPreviousDataNeeded,
        measurementNames: HealthDataRequest.measurementNames,
      },
    };

    try {
      return await this.callHealthDataApi(options);
    } catch (error: any) {
      if (error.error && error.error.errorCode != E_INVALID_TOKEN) {
        throw error;
      } else if (error.errorCode === E_CONFIG_MISSING) {
        throw new FalconError(E_CONFIG_MISSING);
      }
      token = await this.getToken(true);
      if (options.headers) options.headers.Authorization = `Bearer ${token}`;
      return await this.callHealthDataApi(options);
    }
  }
}

function daysInPreviousMonth(month: number, year: number) {
  return new Date(year, month, 0).getDate();
}

function isValidDate(date: Date) {
  return date.getMonth() < 12 && date.getDate() < 32 ? true : false;
}

export default HealthDataAPIClient;
