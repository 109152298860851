import { CreateTimeTrackingInput, Duration } from '../api/CoreAPI';
import CoreAPIClient, { QueryDescriptors } from '../api/CoreAPIClient';
import { createTimeTracking } from '../api/core-graphql/core-mutation';
import { getTimeTrackingSummary } from '../api/core-graphql/core-queries';

const queryDescriptors: QueryDescriptors = {
  getOne: {
    query: getTimeTrackingSummary,
    responseAttribute: 'getTimeTrackingSummary',
  },
  create: {
    query: createTimeTracking,
    responseAttribute: 'createTimeTracking',
  },
};

class PatientTimeTrackingService extends CoreAPIClient<Duration, CreateTimeTrackingInput, null> {
  constructor() {
    super('TimeTracking', queryDescriptors);
  }
}

const patientTimeTrackingService = new PatientTimeTrackingService();

Object.freeze(patientTimeTrackingService);
export default patientTimeTrackingService;
