import { ExitToApp } from '@mui/icons-material';
import { Divider, Menu, MenuItem } from '@mui/material';
import { Profile } from '../actions';
interface DefaultMenuProps {
  isMenuOpen: boolean;
  handleMenuClose: () => void;
  anchorEl: HTMLElement | null;
  signOut: any;
}

export const DefaultMenu = ({ isMenuOpen, handleMenuClose, anchorEl, signOut }: DefaultMenuProps) => {
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id='primary-search-account-menu'
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}>
        <MenuItem onClick={handleMenuClose} sx={{ width: 150 }}>
          <Profile disableTooltip />
          Profile
        </MenuItem>
        <Divider />
        <MenuItem onClick={signOut} sx={{ width: 150 }}>
          <ExitToApp sx={{ mr: 1 }} />
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};
