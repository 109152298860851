import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { FPrimaryButton } from '../fcomponents';
import { falconeerConst } from '../../utils/FalconeerConst';
import Flex from '@react-css/flex';
import './TimerControl.css';
import patientTimeTrackingService from '../../services/PatientTimeTrackingService';
import { CreateTimeTrackingInput } from '../../api/CoreAPI';
import FalconError, { E_CREATE_PATIENT_TIMER_DATA, E_FETCH_PATIENT_TIMER_DATA } from '../../falconeer/FalconErrors';
import {
  POST_LOGIN_CONFIG_KEY_AUTO_START_DELAY,
  POST_LOGIN_CONFIG_KEY_SAVE_TIMER_DATA_FREQUENCY,
} from '../../utils/AppConfigKeys';
import { getAppConfigValue } from '../../utils/Utils';

var unsavedDuration = 0;
export const addTimeTrackingRecord = async (totalDurationInSeconds: number, patientId: string, doctorId: string) => {
  /*         
    Send unsavedDuration.current in payload for duration
    If saved successfully then => unsavedDuration.current =0;
    */
  unsavedDuration += totalDurationInSeconds;
  let createTimeTrackingInput: CreateTimeTrackingInput = {
    doctorId: doctorId,
    patientId: patientId,
    duration: unsavedDuration,
  };
  await patientTimeTrackingService
    .create(createTimeTrackingInput)
    .then(value => {
      unsavedDuration = 0;
    })
    .catch(err => {
      throw new FalconError(E_CREATE_PATIENT_TIMER_DATA);
    });
};

const TimerControl: React.FC<any> = props => {
  const [start, setStart] = useState(false);
  const myInterval: any = useRef();
  const myTimeout: any = useRef();
  let delayForAutoStartTimer: number = getAppConfigValue(POST_LOGIN_CONFIG_KEY_AUTO_START_DELAY); //delay in miliseconds to auto start the timer
  let saveDurationFrequencyMinutes: number = getAppConfigValue(POST_LOGIN_CONFIG_KEY_SAVE_TIMER_DATA_FREQUENCY); //should be configurable per tenant

  const jumpBySeconds = 1;
  const seconds = useRef(0);
  const formatTime = (time: String) => {
    //expected output format 'hh:mm:ss'
    return time
      .split(':')
      .map(t => t.padStart(2, '0'))
      .join(':');
  };
  const convertSecondsToHhMmSs = (totalSeconds: number) => {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    let HsMsSs = hours + ':' + minutes + ':' + seconds;
    return HsMsSs;
  };

  const [timer, setTimer] = useState('00:00:00');

  let durationInSeconds = useRef(0); // This is time spent during single/latest start to stop transition
  //let unsavedDuration = useRef(0); // Duration which could not be saved because of API error
  const startTimer = () => {
    clearTimeout(myTimeout.current); // clear auto start timer
    setStart(true);

    myInterval.current = setInterval(() => {
      durationInSeconds.current += jumpBySeconds;

      seconds.current += jumpBySeconds;

      if (seconds.current % (saveDurationFrequencyMinutes * 60) == 0) {
        //Send data to backend after every minute
        addTimeTrackingRecord(durationInSeconds.current, props.patient.id, props.doctorId);
        durationInSeconds.current = 0;
      }
      setTimer(formatTime(convertSecondsToHhMmSs(seconds.current)));
    }, 1 * 1000);
  };
  const stopTimer = () => {
    clearInterval(myInterval.current);
    setStart(false);
    if (durationInSeconds.current > 0) {
      //Send data to backend after timer is stopped
      addTimeTrackingRecord(durationInSeconds.current, props.patient.id, props.doctorId);
    }
    durationInSeconds.current = 0; //reset duration
  };
  const onFocus = () => {
    clearTimeout(myTimeout.current); // clear auto start timer
    myTimeout.current = setTimeout(startTimer, delayForAutoStartTimer);
  };
  const onBlur = () => {
    clearTimeout(myTimeout.current); // clear auto start timer
    stopTimer();
  };

  useEffect(() => {
    const loadMonthlyDuration = async () => {
      //get time spent current month
      const duration: any = await patientTimeTrackingService
        .getOne({ doctorId: props.doctorId, patientId: props.patient.id })
        .catch(err => {
          throw new FalconError(E_FETCH_PATIENT_TIMER_DATA);
        });
      if (duration) {
        setTimer(formatTime(convertSecondsToHhMmSs(duration.totalDuration)));
        seconds.current = duration.totalDuration;
      }
    };
    loadMonthlyDuration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.overviewTabLoaded === true) {
      myTimeout.current = setTimeout(startTimer, delayForAutoStartTimer);
    }
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      // Send data to backend on component unmount
      addTimeTrackingRecord(durationInSeconds.current, props.patient.id, props.doctorId);
      clearInterval(myInterval.current);
      clearTimeout(myTimeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.overviewTabLoaded]);
  useEffect(() => {
    //use data in logout handler
    window.localStorage.setItem('unsavedDuration', '' + durationInSeconds.current);
    window.localStorage.setItem('unsavedDuration_patientId', '' + props.patient.id);

    //re-render after every second
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <Flex className='timer-control' flexDirection='row' alignItemsCenter>
      <Box className='space-right'>
        <Typography variant='h5' className='text-color-primary'>
          {falconeerConst.timeSpentThisMonth}
        </Typography>
      </Box>
      <Box className='timer space-right'>
        <Typography variant='h5' className='text-color-primary'>
          {timer}
        </Typography>
      </Box>
      <Box className='space-right'>
        {start ? (
          <FPrimaryButton
            onClick={e => {
              stopTimer();
            }}>
            Stop
          </FPrimaryButton>
        ) : (
          <FPrimaryButton
            onClick={e => {
              startTimer();
            }}>
            Start
          </FPrimaryButton>
        )}
      </Box>
    </Flex>
  );
};
export default TimerControl;
