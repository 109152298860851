import {
  // Home as HomeIcon,
  People as UsersIcon,
  Speed as MeasurementIcon,
  Code as DeveloperIcon,
  HomeRepairService as DeviceHubIcon,
  Assignment as Task,
  PersonAdd as ImportPatientIcon,
  Medication as ConditionsIcon,
  Sick as SymptomsIcon,
  Source as ContentIcon,
  GroupAdd as OnboardUsersIcon,
  DevicesOther as PrescribedDevicesIcon,
} from '@mui/icons-material';
import { AppRoute } from './types/Route';
import {
  PAGE_TITLE_MEASUREMENTS,
  PAGE_TITLE_DEVICES,
  PAGE_TITLE_PRESCRIBED_DEVICES,
  // PAGE_TITLE_HOME,
  PAGE_TITLE_PATIENTS,
  PAGE_TITLE_PATIENT_DASHBOARD,
  PAGE_TITLE_CONDITIONS,
  PAGE_TITLE_TASKS,
  PAGE_TITLE_SYMPTOMS,
  PAGE_TITLE_IMPORTED_PATIENTS,
  PAGE_TITLE_CONTENT,
  PAGE_TITLE_ERROR,
  PAGE_PATH_TASKS,
  PAGE_PATH_PATIENTS,
  PAGE_PATH_IMPORTED_PATIENTS,
  PAGE_PATH_PATIENT_DASHBOARD,
  PAGE_PATH_DEVICES,
  PAGE_PATH_PRESCRIBED_DEVICES,
  PAGE_PATH_MEASUREMENTS,
  PAGE_PATH_CONDITIONS,
  PAGE_PATH_SYMPTOMS,
  PAGE_PATH_CONTENT,
  PAGE_PATH_ERROR,
  PAGE_TITLE_ONBOARD_USER,
  PAGE_PATH_ONBOARD_USER,
  PAGE_TITLE_SHARED_DEVICE,
  PAGE_PATH_SHARED_DEVICE,
} from './utils/constants';
import { lazy } from 'react';
// import home from './assets/images/home.png';
import { TaskDashboard } from './pages/task/TaskDashboard';
import { SharedDeviceListPage } from './pages/sharedDevices/SharedDeviceListPage';

// const HomePage = lazy(() => import('./pages/HomePage').then(({ HomePage }) => ({ default: HomePage })));
const PatientListPage = lazy(() =>
  import('./pages/patient/PatientListPage').then(({ PatientListPage }) => ({ default: PatientListPage })),
);

const PatientDashboard = lazy(() =>
  import('./pages/patient/PatientDashboard').then(({ PatientDashboard }) => ({ default: PatientDashboard })),
);

const MeasurementListPage = lazy(() =>
  import('./pages/measurement/MeasurementListPage').then(({ MeasurementListPage }) => ({
    default: MeasurementListPage,
  })),
);

const DeviceListPage = lazy(() =>
  import('./pages/device/DeviceListPage').then(({ DeviceListPage }) => ({ default: DeviceListPage })),
);

const PrescribedDeviceListPage = lazy(() =>
  import('./pages/prescribedDevice/PrescribedDeviceListPage').then(({ PrescribedDeviceListPage }) => ({
    default: PrescribedDeviceListPage,
  })),
);

const ConditionListPage = lazy(() =>
  import('./pages/condition/ConditionListPage').then(({ ConditionListPage }) => ({ default: ConditionListPage })),
);

const SymptomListPage = lazy(() =>
  import('./pages/symptom/SymptomListPage').then(({ SymptomListPage }) => ({ default: SymptomListPage })),
);

const ContentListPage = lazy(() =>
  import('./pages/content/ContentListPage').then(({ ContentListPage }) => ({ default: ContentListPage })),
);
const OnboardUserListPage = lazy(() =>
  import('./pages/onboardUser/OnboardUserListPage').then(({ OnboardUserListPage }) => ({
    default: OnboardUserListPage,
  })),
);

const ImportedPatientListPage = lazy(() =>
  import('./pages/imported-patient/ImportedPatientListPage').then(({ ImportedPatientListPage }) => ({
    default: ImportedPatientListPage,
  })),
);
const ErrorPage = lazy(() =>
  import('./pages/ErrorPage').then(({ ErrorPage }) => ({
    default: ErrorPage,
  })),
);
const GetUserInfoPage = lazy(() =>
  import('./pages/AuthScreens/GetUserInfo').then(({ GetUserInfo }) => ({
    default: GetUserInfo,
  })),
);
const SignInPage = lazy(() =>
  import('./pages/AuthScreens/SignIn').then(({ SignIn }) => ({
    default: SignIn,
  })),
);
const SignUpPage = lazy(() =>
  import('./pages/AuthScreens/SignUp').then(({ SignUp }) => ({
    default: SignUp,
  })),
);
const ConfirmCodePage = lazy(() =>
  import('./pages/AuthScreens/ConfirmCode').then(({ ConfirmCode }) => ({
    default: ConfirmCode,
  })),
);
const ForgotPasswordPage = lazy(() =>
  import('./pages/AuthScreens/ForgotPassword').then(({ ForgotPassword }) => ({
    default: ForgotPassword,
  })),
);

const routes: Array<AppRoute> = [
  // {
  //   iconUrl: home,
  //   key: 'router-home',
  //   title: PAGE_TITLE_HOME,
  //   description: 'Home',
  //   component: <HomePage />,
  //   path: '/home',
  //   isEnabled: true,
  //   icon: HomeIcon,
  //   isMenu: true,
  //   appendDivider: false,
  // },
  {
    iconUrl: 'menu_task.png',
    key: 'router-tasks',
    title: PAGE_TITLE_TASKS,
    description: 'Tasks',
    path: PAGE_PATH_TASKS,
    isEnabled: true,
    icon: Task,
    component: <TaskDashboard />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: 'menu_patient.png',
    key: 'router-patients',
    title: PAGE_TITLE_PATIENTS,
    description: 'Patients',
    path: PAGE_PATH_PATIENTS,
    isEnabled: true,
    icon: UsersIcon,
    component: <PatientListPage />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: 'menu_imported_patient.png',
    key: 'router-imported-patients',
    title: PAGE_TITLE_IMPORTED_PATIENTS,
    description: 'Imported Patients',
    path: PAGE_PATH_IMPORTED_PATIENTS,
    isEnabled: true,
    icon: ImportPatientIcon,
    component: <ImportedPatientListPage />,
    isMenu: true,
    appendDivider: true,
    type: 'secured',
  },
  {
    iconUrl: 'menu_patient.png',
    key: 'router-patient-dashboard',
    title: PAGE_TITLE_PATIENT_DASHBOARD,
    description: 'Patient Dashboard',
    path: PAGE_PATH_PATIENT_DASHBOARD,
    isEnabled: true,
    icon: UsersIcon,
    component: <PatientDashboard />,
    isMenu: false,
    type: 'secured',
  },
  {
    iconUrl: 'menu_device.png',
    key: 'router-devices',
    title: PAGE_TITLE_DEVICES,
    description: 'Devices',
    path: PAGE_PATH_DEVICES,
    isEnabled: true,
    icon: DeviceHubIcon,
    component: <DeviceListPage />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: 'menu_measurements.png',
    key: 'router-measurements',
    title: PAGE_TITLE_MEASUREMENTS,
    description: 'Measurements',
    path: PAGE_PATH_MEASUREMENTS,
    isEnabled: true,
    icon: MeasurementIcon,
    component: <MeasurementListPage />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: 'menu_condition.jpg',
    key: 'router-conditions',
    title: PAGE_TITLE_CONDITIONS,
    description: 'Conditions',
    path: PAGE_PATH_CONDITIONS,
    isEnabled: true,
    icon: ConditionsIcon,
    component: <ConditionListPage />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: 'menu_symptom.png',
    key: 'router-symptoms',
    title: PAGE_TITLE_SYMPTOMS,
    description: 'Symptoms',
    path: PAGE_PATH_SYMPTOMS,
    isEnabled: true,
    icon: SymptomsIcon,
    component: <SymptomListPage />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: 'menu_content.png',
    key: 'router-content',
    title: PAGE_TITLE_CONTENT,
    description: 'content',
    path: PAGE_PATH_CONTENT,
    isEnabled: true,
    icon: ContentIcon,
    component: <ContentListPage />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: 'device.png',
    key: 'router-prescribeddevices',
    title: PAGE_TITLE_PRESCRIBED_DEVICES,
    description: 'Prescribed Devices',
    path: PAGE_PATH_PRESCRIBED_DEVICES,
    isEnabled: true,
    icon: PrescribedDevicesIcon,
    component: <PrescribedDeviceListPage />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: '',
    key: 'router_onboardUser',
    title: PAGE_TITLE_ONBOARD_USER,
    description: 'Onboard User',
    path: PAGE_PATH_ONBOARD_USER,
    isEnabled: true,
    icon: OnboardUsersIcon,
    component: <OnboardUserListPage />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: '',
    key: 'router_sharedDevice',
    title: PAGE_TITLE_SHARED_DEVICE,
    description: 'Shared Device',
    path: PAGE_PATH_SHARED_DEVICE,
    isEnabled: true,
    icon: DeveloperIcon,
    component: <SharedDeviceListPage />,
    isMenu: true,
    type: 'secured',
  },
  {
    iconUrl: 'menu_content.png',
    key: 'router-error',
    title: PAGE_TITLE_ERROR,
    description: 'Go to Error Page',
    path: PAGE_PATH_ERROR,
    isEnabled: true,
    icon: DeveloperIcon,
    component: <ErrorPage />,
    isMenu: false,
    type: 'secured',
  },
  {
    iconUrl: 'menu_content.png',
    key: 'router-error-unsecured',
    title: PAGE_TITLE_ERROR,
    description: 'Go to Error Page',
    path: '/app-error',
    isEnabled: true,
    icon: DeveloperIcon,
    component: <ErrorPage />,
    isMenu: false,
    type: 'unsecured',
  },
  {
    key: 'router_getUserInfo',
    iconUrl: '',
    title: '',
    isEnabled: false,
    description: 'Go to Get User Information page',
    path: '/',
    component: <GetUserInfoPage />,
    type: 'unsecured',
  },
  {
    key: 'router_signIn',
    iconUrl: '',
    title: '',
    isEnabled: false,
    description: 'Go to Sign In page',
    path: '/signin',
    component: <SignInPage />,
    type: 'unsecured',
  },
  {
    key: 'router_signUp',
    iconUrl: '',
    title: '',
    isEnabled: false,
    description: 'Go to Sign Up page',
    path: '/signup',
    component: <SignUpPage />,
    type: 'unsecured',
  },
  {
    key: 'router_confirmcode',
    iconUrl: '',
    title: '',
    isEnabled: false,
    description: 'Go to Confirm Code page',
    path: '/confirmcode',
    component: <ConfirmCodePage />,
    type: 'unsecured',
  },
  {
    key: 'router_forgotpassword',
    iconUrl: '',
    title: '',
    isEnabled: false,
    description: 'Go to Forgot Password page',
    path: '/forgotpassword',
    component: <ForgotPasswordPage />,
    type: 'unsecured',
  },
];

export default routes;
