import { Button } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { CustomDialog } from '../../styles/CommonStyles';

type UseModalOpenReturnType = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const useModalOpen = (): UseModalOpenReturnType => {
  const [open, setOpen] = useState(false);

  return {
    open,
    setOpen,
  };
};

type ModalContextType = {
  showConfirmation: (message: string, title?: string) => Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
  children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmationModalContextProvider: React.FC<ConfirmationModalContextProviderProps> = props => {
  const { setOpen, open } = useModalOpen();
  const [content, setContent] = useState<{ message: string; title?: string } | null>();
  const resolver = useRef<Function>();

  const handleOpen = (message: string, title: string = 'Confirmation'): Promise<boolean> => {
    setContent({
      message,
      title,
    });
    setOpen(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showConfirmation: handleOpen,
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    setOpen(false);
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setOpen(false);
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}

      {content && (
        <CustomDialog open={open} disableEscapeKeyDown>
          <DialogTitle>{content.title}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>{content.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOk}>Yes</Button>
            <Button onClick={handleCancel}>No</Button>
          </DialogActions>
        </CustomDialog>
      )}
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);

export { useModalOpen, useConfirmationModalContext };

export default ConfirmationModalContextProvider;
