import { updateUser } from '../api/core-graphql/core-mutation';
import { getUser, listUsers } from '../api/core-graphql/core-queries';
import { CreateUserInput, UpdateUserInput, User } from '../api/CoreAPI';
import CoreAPIClient, { QueryDescriptors } from '../api/CoreAPIClient';

const queryDescriptors: QueryDescriptors = {
  getOne: {
    query: getUser,
    responseAttribute: 'getUser',
  },
  update: {
    query: updateUser,
    responseAttribute: 'updateUser',
  },
  getAll: {
    query: listUsers,
    responseAttribute: 'listUsers',
  },
  create: null,
  delete: null,
};

class UserService extends CoreAPIClient<User, CreateUserInput, UpdateUserInput> {
  constructor() {
    super('User', queryDescriptors);
  }
}

const userService = new UserService();
Object.freeze(userService);

export default userService;
