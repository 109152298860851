import {
  TypedDocumentNode,
  DocumentNode,
  ApolloClient,
  InMemoryCache,
  QueryOptions,
  MutationOptions,
  NormalizedCacheObject,
} from '@apollo/client';
import FalconError, * as Errors from '../falconeer/FalconErrors';
import { QueryVariables } from '../components/entity-manager/types';
import { falconStore } from '../falconeer/FalconStore';
import { getAppConfigValue } from '../utils/Utils';
import { PRE_LOGIN_CONFIG_KEY_CORE_SERVICE_URL } from '../utils/AppConfigKeys';
import { ErrorMappingToGetCode } from '../utils/FalconeerConst';
export type QueryDescriptor = {
  query: DocumentNode | TypedDocumentNode<any, any>;
  responseAttribute: string;
};

export type ErrorModel = {
  type: string;
  errors: [{ message: string }];
};

export type QueryName = 'getAll' | 'getOne' | 'create' | 'update' | 'delete';

export type QueryDescriptors = Partial<Record<QueryName, QueryDescriptor | null>>;

export default abstract class CoreAPIClient<T, C, U> {
  static graphQLClient: ApolloClient<NormalizedCacheObject>;

  constructor(public readonly entityName: string, public readonly queryDescriptors: QueryDescriptors) {}
  private getGraphQLClient(): ApolloClient<NormalizedCacheObject> {
    const coreServiceUrl = getAppConfigValue(PRE_LOGIN_CONFIG_KEY_CORE_SERVICE_URL);
    if (!CoreAPIClient.graphQLClient && coreServiceUrl) {
      CoreAPIClient.graphQLClient = new ApolloClient({
        uri: coreServiceUrl,
        cache: new InMemoryCache(),
        defaultOptions: {
          query: {
            fetchPolicy: 'network-only',
          },
          watchQuery: { fetchPolicy: 'no-cache' },
        },
      });
    }

    return CoreAPIClient.graphQLClient;
  }

  async getContext(refreshToken = false) {
    return {
      headers: {
        authorization: `Bearer ` + (await falconStore.getToken(refreshToken)),
      },
    };
  }
  async getNewContext() {
    const newContext = await this.getContext(true);
    return newContext;
  }
  async executeQuery(operationName: string, options: QueryOptions | MutationOptions) {
    const operation: any = operationName === 'query' ? this.getGraphQLClient().query : this.getGraphQLClient().mutate;
    try {
      const data = await operation({
        ...options,
        context: await this.getContext(),
      });
      if (data.data.code && [Errors.E_UNAUTHORIZED_ERROR, Errors.E_INVALID_TOKEN].includes(data.data.code)) {
        return await operation({
          ...options,
          context: await this.getContext(true),
        });
      }
      return data;
    } catch (err: any) {
      if (err.graphQLErrors) {
        for (let error of err.graphQLErrors) {
          if (error?.code === Errors.E_EXPIRED_TOKEN) {
            return await operation({
              ...options,
              context: await this.getContext(true),
            });
          } else if (ErrorMappingToGetCode[error.code]) {
            throw error.code;
          }
        }
      }
    }
  }

  async getAll(variables?: QueryVariables) {
    if (!this.queryDescriptors.getAll) {
      throw new FalconError(Errors.E_API_ERROR, 'getAll - query not defined');
    }
    try {
      const data = await this.executeQuery('query', {
        query: this.queryDescriptors.getAll.query,
        variables: variables,
      });
      if (!data.data[this.queryDescriptors.getAll.responseAttribute]) {
        throw new Error('Invalid response attribute: ' + this.queryDescriptors.getAll.responseAttribute);
      }

      return data.data[this.queryDescriptors.getAll.responseAttribute] as T[];
    } catch (err: any) {
      if (err && err.errors && err.errors.length > 0) {
        throw new FalconError(Errors.E_API_ERROR, JSON.stringify(err.errors));
      } else {
        throw new FalconError(Errors.E_API_ERROR, 'getAll ' + this.entityName + ' error: ' + err.message);
      }
    }
  }

  async getOne(variables: Record<string, any>) {
    if (!this.queryDescriptors.getOne) {
      throw new FalconError(Errors.E_API_ERROR, 'getOne - query not defined');
    }

    try {
      const data = await this.executeQuery('query', {
        query: this.queryDescriptors.getOne.query,
        variables: variables,
      });
      return data.data[this.queryDescriptors.getOne.responseAttribute] as T;
    } catch (err: any) {
      if (err && err.errors && err.errors.length > 0) {
        throw new FalconError(Errors.E_API_ERROR, JSON.stringify(err.errors));
      } else {
        throw new FalconError(Errors.E_API_ERROR, 'getOne ' + this.entityName + ' error: ' + err.message);
      }
    }
  }

  async create(input: C) {
    if (!this.queryDescriptors.create) {
      throw new FalconError(Errors.E_API_ERROR, 'create - query not defined');
    }

    try {
      const data = await this.executeQuery('mutation', {
        mutation: this.queryDescriptors.create.query,
        variables: { input },
      });
      return data.data[this.queryDescriptors.create.responseAttribute] as T;
    } catch (err: any) {
      if (ErrorMappingToGetCode[err]) {
        throw err;
      } else if (err && err.errors && err.errors.length > 0) {
        throw new FalconError(Errors.E_API_ERROR, JSON.stringify(err.errors));
      } else {
        throw new FalconError(Errors.E_API_ERROR, 'create ' + this.entityName + ' error: ' + err.message);
      }
    }
  }

  async update(id: string, input: U) {
    if (!this.queryDescriptors.update) {
      throw new FalconError(Errors.E_API_ERROR, 'update - query not defined');
    }

    try {
      const response = await this.executeQuery('mutation', {
        mutation: this.queryDescriptors.update.query,
        variables: { id, input },
      });
      return response.data[this.queryDescriptors.update.responseAttribute] as T;
    } catch (err: any) {
      if (err && err.errors && err.errors.length > 0) {
        throw new FalconError(Errors.E_API_ERROR, JSON.stringify(err.errors));
      } else {
        throw new FalconError(Errors.E_API_ERROR, 'update ' + this.entityName + ' error: ' + err.message);
      }
    }
  }

  async delete(id: string) {
    if (!this.queryDescriptors.delete) {
      throw new FalconError(Errors.E_API_ERROR, 'delete - query not defined');
    }
    try {
      const variables = { id: id };
      const response = await this.executeQuery('mutation', {
        mutation: this.queryDescriptors.delete.query,
        variables: variables,
      });
      return response.data[this.queryDescriptors.delete.responseAttribute] as T;
    } catch (err: any) {
      if (err && err.errors && err.errors.length > 0) {
        throw new FalconError(Errors.E_API_ERROR, JSON.stringify(err.errors));
      } else {
        throw new FalconError(Errors.E_API_ERROR, 'delete ' + this.entityName + ' error: ' + err.message);
      }
    }
  }
}
