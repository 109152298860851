export const PRIMARY_COLOR = '#034273';
export const SECONDARY_COLOR = '#8993A4';
export const PRIMARY_HEADER_COLOR = '#030A73';
export const WHITE_COLOR = '#FFFFFF';
export const LIGHT_GRAY = '#F3F3F3';
export const PRIMARY_LIGHT = '#B9DAEA';
export const DARK_GREY = '#A9A9A9';
export const APPLICATION_BACKGROUND = '#D7DADC';
export const SECONDARY_GRAY = '#6B778C';
export const PURPLE_COLOR = '#800080';
export const BLACK_COLOR = '#000000';
export const RED_COLOR = '#730342';
export const YELLOW_COLOR = '#F9BC93';
export const GREEN_COLOR = '#03736B';
export const ORANGE_COLOR = '#F9BC93';
export const DISABLED_GREY = '#868686';
