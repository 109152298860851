import axios, { AxiosRequestConfig } from 'axios';
import { POST_LOGIN_CONFIG } from './utils/constants';
import FalconError, { E_CONFIG_ERROR } from './falconeer/FalconErrors';
import { CURRENT_APP_VERSION, PROVIDER_WEB_APP_ID } from './api/types';

class PostLoginConfiguration {
  public config: any | null = null;

  public async load(token: string): Promise<any> {
    if (this.config) {
      return this.config;
    }
    const options: AxiosRequestConfig<any> = {
      method: 'GET',
      url: `${POST_LOGIN_CONFIG}?platformId=${PROVIDER_WEB_APP_ID}&platformVersion=${CURRENT_APP_VERSION}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.request(options).catch(err => {
      throw new FalconError(E_CONFIG_ERROR);
    });
    this.config = response?.data.data.configs;
    return this.config;
  }
}

const postLoginConfiguration = new PostLoginConfiguration();

export default postLoginConfiguration;
