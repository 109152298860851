import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { TableColumn } from './types';
import { Order } from './utils';
import { StyledTableHeader } from '../../styles/CommonStyles';
import './EntityTableHead.css';

type EntityTableHeadProps = {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: TableColumn) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: TableColumn;
  rowCount: number;
  columns: TableColumn[];
  mainConfig?: any;
};

export function EntityTableHead(props: EntityTableHeadProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: TableColumn) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableHeader>
        {!props?.mainConfig?.disableMultiSelect && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all',
              }}
            />
          </TableCell>
        )}
        {props.columns.map(column => (
          <TableCell
            key={column.attributeName}
            align={column.align ? column.align : 'left'}
            sortDirection={orderBy.attributeName === column.attributeName ? order : false}
            sx={{ fontSize: '16px', padding: '10px 16px' }}>
            <TableSortLabel
              active={orderBy.attributeName === column.attributeName}
              disabled={column.hideSortIcon ? true : false}
              hideSortIcon={column.hideSortIcon ? true : false}
              direction={orderBy.attributeName === column.attributeName ? order : 'asc'}
              onClick={createSortHandler(column)}>
              {column.title}
            </TableSortLabel>
            {column.filter && <div className='prescribed-device-filter-style'>{<column.filter column={column} />}</div>}
          </TableCell>
        ))}
        <TableCell></TableCell>
      </StyledTableHeader>
    </TableHead>
  );
}
