import { CreateSharedDeviceInput, SharedDevice, UpdateSharedDeviceInput } from '../api/CoreAPI';
import CoreAPIClient, { QueryDescriptors } from '../api/CoreAPIClient';
import { createSharedDevice, deleteSharedDevice, updateSharedDevice } from '../api/core-graphql/core-mutation';
import { getSharedDevice, listSharedDevice } from '../api/core-graphql/core-queries';
const queryDescriptors: QueryDescriptors = {
  getOne: {
    query: getSharedDevice,
    responseAttribute: 'getSharedDevice',
  },
  update: {
    query: updateSharedDevice,
    responseAttribute: 'updateSharedDevice',
  },
  getAll: {
    query: listSharedDevice,
    responseAttribute: 'listSharedDevice',
  },
  create: {
    query: createSharedDevice,
    responseAttribute: 'createSharedDevice',
  },
  delete: {
    query: deleteSharedDevice,
    responseAttribute: 'deleteSharedDevice',
  },
};

class SharedDeviceService extends CoreAPIClient<SharedDevice, CreateSharedDeviceInput, UpdateSharedDeviceInput> {
  constructor() {
    super('SharedDevice', queryDescriptors);
  }
}

const sharedDeviceService = new SharedDeviceService();
Object.freeze(sharedDeviceService);

export default sharedDeviceService;
