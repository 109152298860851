import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { ActionButton, getPageRange } from './utils';
import {
  CloseIconWrapper,
  SearchBoxWrapper,
  StyledSearch,
  SearchIconWrapper,
  StyledInputBase,
} from '../../styles/CommonStyles';
import { falconeerConst } from '../../utils/FalconeerConst';

export const Search = (props: any) => {
  const [enteredText, setEnteredText] = React.useState('');
  const searchData = () => {
    const recordsPerPage = props.recordsPerPage;
    const response: any = props.search(enteredText, 1, recordsPerPage);
    response.then((data: any) => {
      props.setResponseData(data);
      props.setSearchData(enteredText);
      const totalRecords = data.totalRecords;
      const pageRange = getPageRange(totalRecords, recordsPerPage, props.currentPage);
      props.setCurrentRecordsDisplay(`1-${pageRange}`);
      props.setIsLastPage(totalRecords < recordsPerPage);
      props.setCurrentPage(1);
    });
  };

  const clearSearchData = () => {
    setEnteredText('');
    const recordsPerPage = props.recordsPerPage;
    const response: any = props.search('', 1, recordsPerPage);
    response.then((data: any) => {
      props.setResponseData(data);
      props.setSearchData('');
      const totalRecords = data.totalRecords;
      const pageRange = getPageRange(totalRecords, recordsPerPage, props.currentPage);
      props.setCurrentRecordsDisplay(`1-${pageRange}`);
      props.setCurrentPage(1);
    });
  };

  return (
    <SearchBoxWrapper>
      <StyledSearch>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          disabled={props.disabled}
          placeholder={falconeerConst.search}
          inputProps={{ 'aria-label': falconeerConst.searchLabel }}
          onChange={e => setEnteredText(e.target.value)}
          value={enteredText}
        />
      </StyledSearch>
      {Boolean(enteredText) && (
        <CloseIconWrapper>
          <CloseIcon onClick={() => clearSearchData()} />
        </CloseIconWrapper>
      )}
      <ActionButton onClick={() => searchData()} variant='outlined'>
        Search
      </ActionButton>
    </SearchBoxWrapper>
  );
};
