import React, { Component, ErrorInfo, ReactNode } from 'react';
import { useLocation, Location } from 'react-router-dom';
import { errorMessages, E_CONFIG_MISSING } from '../falconeer/FalconErrors';
import '../pages/ErrorPage.css';

interface Props {
  children: ReactNode;
  location?: Location;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: '',
  };

  public componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    console.log('getDerivedStateFromError error>>>', error);
    return { hasError: true, errorMessage: error.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('componentDidCatch error>>>', error);
    console.error('Uncaught error:', error, errorInfo);
    console.log('componentDidCatch error.message>>>', error.message);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className='error-text'>
          {this.state.errorMessage === errorMessages[E_CONFIG_MISSING] && <h1>{this.state.errorMessage}</h1>}
          {this.state.errorMessage !== errorMessages[E_CONFIG_MISSING] && (
            <h1>Sorry.. there was an error. Try reloading the page.</h1>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

function withCustomRouter(Component: React.ComponentClass<Props>) {
  return function WrappedComponent(props: Props) {
    const location = useLocation();
    return <Component {...props} location={location} />;
  };
}

export default withCustomRouter(ErrorBoundary);
