// preLoginConfig keys
export const PRE_LOGIN_CONFIG_KEY_S3URL = 's3Url';
export const PRE_LOGIN_CONFIG_KEY_TERMS_URL = 'termsUrl';
export const PRE_LOGIN_CONFIG_KEY_POLICY_URL = 'policyUrl';
export const PRE_LOGIN_CONFIG_KEY_SUPPORTED_TIME_ZONES_URL = 'supportedTimeZones';
export const PRE_LOGIN_CONFIG_KEY_CORE_SERVICE_URL = 'coreServiceUrl';
export const PRE_LOGIN_CONFIG_KEY_AUTH_URL = 'auth.url';
export const PRE_LOGIN_CONFIG_KEY_SINGIN = 'auth.paths.signIn';
export const PRE_LOGIN_CONFIG_KEY_SINGUP = 'auth.paths.signUp';
export const PRE_LOGIN_CONFIG_KEY_CONFIRM_CODE = 'auth.paths.confirmCode';
export const PRE_LOGIN_CONFIG_KEY_RESEND_CODE = 'auth.paths.resendCode';
export const PRE_LOGIN_CONFIG_KEY_FORGOT_PWD = 'auth.paths.forgotPassword';
export const PRE_LOGIN_CONFIG_KEY_CONFIRM_PWD = 'auth.paths.confirmPassword';
export const PRE_LOGIN_CONFIG_KEY_CHECK_USER = 'auth.paths.checkUser';
export const PRE_LOGIN_CONFIG_KEY_R_TOKEN = 'auth.paths.refreshToken';

// postLoginConfig keys
export const POST_LOGIN_CONFIG_KEY_HEALTHDATA_URL = 'healthdata';
export const POST_LOGIN_CONFIG_KEY_AUTO_START_DELAY = 'delayForAutoStartTimer';
export const POST_LOGIN_CONFIG_KEY_SUPPORTED_TIMEZONES = 'supportedTimeZones';
export const POST_LOGIN_CONFIG_KEY_SAVE_TIMER_DATA_FREQUENCY = 'saveDurationFrequencyMinutes';
export const POST_LOGIN_CONFIG_KEY_TASK_DUE_DATE_LIMIT_HOURS = 'task.dueDateLimitHours';
export const DEFAULT_COPY_DATA_TEMPLATE = 'default_copy_data_template_name';
