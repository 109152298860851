import { Box, Modal } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const OverlayLoader = (props: { isLoading: boolean }) => {
  const [startLoader, setStartLoader] = useState(false);
  const myTimeout: any = useRef();

  useEffect(() => {
    if (props.isLoading) {
      setStartLoader(true);
    } else {
      setStartLoader(false);
    }
    // clear on component unmount
    return () => {
      clearTimeout(myTimeout.current);
    };
  }, [props.isLoading]);
  return (
    <Modal open={startLoader} disableAutoFocus={true} hideBackdrop={true}>
      <Box
        sx={{
          height: '300px',
          width: '300px',
          position: 'fixed',
          top: '50%',
          left: '50%',
          marginLeft: '-150px',
          marginTop: '-150px',
        }}>
        <svg
          style={{ margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto' }}
          width='300px'
          height='300px'
          viewBox='0 0 100 100'
          preserveAspectRatio='xMidYMid'>
          <path d='M35 50A15 15 0 0 0 65 50A15 16.8 0 0 1 35 50' fill='#034272' stroke='none'>
            <animateTransform
              attributeName='transform'
              type='rotate'
              dur='0.66s'
              repeatCount='indefinite'
              keyTimes='0;1'
              values='0 50 50.9;360 50 50.9'></animateTransform>
          </path>
        </svg>
      </Box>
    </Modal>
  );
};
export default OverlayLoader;
