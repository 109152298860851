import { SortOrder, StatusType, TaskType, UpdateTaskInput } from '../../api/CoreAPI';
import {
  TableColumn as EntityDisplayAttribute,
  EntityConfig,
  DataFetcher,
  ResponseData,
  ActionHandler,
  QueryVariables,
} from '../../components/entity-manager/types';
import taskService from '../../services/TaskService';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { falconeerConst } from '../../utils/FalconeerConst';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Popover from '@mui/material/Popover';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { GREEN_COLOR, DARK_GREY } from '../../components/fcomponents/ColorCodes';
import { formatDateTime, getAppConfigValue } from '../../utils/Utils';
import './TaskConfig.css';
import FalconError, { E_GET_SEARCH_TASKS, E_GET_TASKS, E_UPDATE_TASKS } from '../../falconeer/FalconErrors';
import { POST_LOGIN_CONFIG_KEY_TASK_DUE_DATE_LIMIT_HOURS } from '../../utils/AppConfigKeys';

var requestObj = {};

const updatedAt: EntityDisplayAttribute = {
  attributeName: 'updatedAt',
  disablePadding: false,
  title: 'Updated On',
  dataType: 'string',
  disableSearch: true,
  formatData: (value: Date): string => {
    return formatDateTime(value, false);
  },
};

const nameColumn: EntityDisplayAttribute = {
  attributeName: 'user',
  disablePadding: false,
  title: 'Patient Name',
  dataType: 'string',
  formatData: (value): string => {
    return value?.firstName + ' ' + value?.lastName;
  },
};

const refreshIconColumn: EntityDisplayAttribute = {
  attributeName: 'status',
  title: '',
  disablePadding: false,
  dataType: 'avatar',
  getComp: (value: string): EmotionJSX.Element => {
    return (
      <>
        {value === StatusType.Pending ? (
          <Tooltip title={falconeerConst.markComplete}>
            <Button>
              <CheckCircleOutlineIcon sx={{ color: DARK_GREY }} />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title={falconeerConst.markPending}>
            <Button>
              <CheckCircleIcon sx={{ color: GREEN_COLOR }} />
            </Button>
          </Tooltip>
        )}
      </>
    );
  },
};

const columns: EntityDisplayAttribute[] = [
  updatedAt,
  nameColumn,
  {
    attributeName: 'type',
    disablePadding: false,
    title: 'Task Type',
    dataType: 'string',
  },
  {
    attributeName: 'reason',
    disablePadding: false,
    title: 'Reason',
    dataType: 'string',
    disableSearch: true,
  },
  {
    attributeName: 'dueDate',
    disablePadding: false,
    title: 'Due Date',
    dataType: 'string',
    disableSearch: true,
    getDataFromRow: (data: any): EmotionJSX.Element => {
      return (
        <>
          <FormatDueDate data={data} />
        </>
      );
    },
  },
  {
    attributeName: 'status',
    disablePadding: false,
    title: (
      <>
        <Typography>{'Status'}</Typography>
      </>
    ),
    dataType: 'string',
    disableSearch: true,
    filter: props => <BasicPopover column={props.column} />,
  },
  {
    attributeName: 'remarks',
    disablePadding: false,
    title: 'Remarks',
    dataType: 'string',
    disableSearch: true,
  },
  {
    attributeName: 'buttonText',
    disablePadding: false,
    title: '',
    dataType: 'string',
    disableSearch: true,
  },
  refreshIconColumn,
];

function FormatDueDate(props: any) {
  const dueDateLimitHours = getAppConfigValue(POST_LOGIN_CONFIG_KEY_TASK_DUE_DATE_LIMIT_HOURS);
  const { data } = props;
  let date = new Date();
  date.setHours(0, 0, 0, 0);
  let dueDate = new Date(data.dueDate);
  dueDate.setHours(0, 0, 0, 0);
  const dueDateLimit = new Date(dueDate.getTime() - dueDateLimitHours * 60 * 60 * 1000);
  const isDueDateReaching = date >= dueDateLimit;

  return (
    <Box className={isDueDateReaching && data.status === StatusType.Pending ? 'due-date-color' : ''}>
      {formatDateTime(data.dueDate, false)}
    </Box>
  );
}

function BasicPopover(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [value, setValue] = React.useState('All');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    let filterValue = (event.target as HTMLInputElement).value;
    if (filterValue === 'All') {
      requestObj = {};
    } else {
      requestObj = { status: { eq: filterValue } };
    }
    TaskConfig.handleRequestSort(null, props.column);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className='status-filter-alignment'>
      <IconButton size='small' color='inherit' onClick={handleClick}>
        <FilterAltIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <FormControl sx={{ p: 2 }}>
          <RadioGroup
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={value}
            onChange={handleChange}>
            <FormControlLabel value='All' control={<Radio />} label='All' />
            <FormControlLabel value='Pending' control={<Radio />} label='Pending' />
            <FormControlLabel value='Completed' control={<Radio />} label='Completed' />
          </RadioGroup>
        </FormControl>
      </Popover>
    </div>
  );
}

export const getTask: DataFetcher = async (dataQuery: QueryVariables) => {
  if (requestObj) {
    dataQuery.filter = requestObj;
  }
  const task: any = await taskService.getAll(dataQuery).catch(err => {
    throw new FalconError(E_GET_TASKS);
  });

  const totalRecords = task.pagination.totalRecords;
  const totalPages = Math.ceil(totalRecords / 20);

  const data: ResponseData = {
    pageNumber: 1,
    recordsPerPage: 20,
    totalRecords: totalRecords,
    totalPages: totalPages,
    records: task.items,
  };
  return data;
};

const searchTask: any = async (searchtext: string, pageNumber: number = 1, recordsPerPage: number = 20) => {
  const offset = recordsPerPage * (pageNumber - 1);
  const requestObj: QueryVariables = {
    pageNo: 0,
    pageSize: 30,
    orderBy: 'id',
    orderDirection: 'asc',
    search: {
      keyword: searchtext,
    },
    pagination: {
      limit: recordsPerPage,
      offset: offset,
    },
    filter: {},
  };

  const tasks: any = await taskService.getAll(requestObj).catch(err => {
    throw new FalconError(E_GET_SEARCH_TASKS);
  });
  const totalRecords = tasks.pagination.totalRecords;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const data: ResponseData = {
    pageNumber: pageNumber,
    recordsPerPage: recordsPerPage,
    totalRecords: totalRecords,
    totalPages: totalPages,
    records: tasks.items,
  };
  return data;
};

const addContent: ActionHandler = async (action: string, payload: any | null) => {
  if (payload) {
    console.log(action);
  }
};

const updateContent: ActionHandler = async (action: string, payload: any) => {
  if (payload) {
    const task: UpdateTaskInput = {
      type: payload.type,
      reason: payload.reason,
      dueDate: payload.dueDate,
      remarks: payload.remarks,
      status: payload.status,
    };
    await taskService.update(payload.id, task).catch(err => {
      throw new FalconError(E_UPDATE_TASKS);
    });
  }
  TaskConfig.refreshEntityData = new Date();
};

const actionMap: { [key: string]: ActionHandler } = {
  add: addContent,
  update: updateContent,
};

const actionHandler: ActionHandler = async (action: string, payload: any | null) => {
  if (actionMap[action]) {
    await actionMap[action](action, payload);
  }
};
const formSchema = {
  title: 'Task Management',
  description: 'Task will have additional context or metadata describing their purpose.',
  type: 'object',
  required: ['type', 'status'],
  properties: {
    type: {
      type: 'string',
      title: 'Task type',
      enum: Object.keys(TaskType),
    },
    status: {
      type: 'string',
      title: 'Status',
    },
  },
};

const uiSchema = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      scope: '#/properties/type',
    },
    {
      type: 'Control',
      scope: '#/properties/status',
      options: {
        multi: true,
      },
    },
  ],
};

export const TaskConfig: EntityConfig = {
  columns: columns,
  idColumnName: 'patientId',
  title: 'Task Management',
  drillDownColumn: nameColumn,
  refreshIconColumn: refreshIconColumn,
  entityName: 'Task',
  entityNamePlural: 'Tasks',
  actions: null,
  actionHandler: actionHandler,
  orderByColumn: updatedAt,
  orderDirection: 'desc',
  fetch: getTask,
  search: searchTask,
  showSearch: true,
  showNewPagination: true,
  disableAdd: true,
  disableEdit: true,
  disableDetails: true,
  disableDelete: true,
  disableMultiSelect: true,
  formSchema: formSchema,
  uiSchema: uiSchema,
  customActions: null,
  customIconHandler: [],
  handleRequestSort: null,
  getFormattedRow: (row: any) => {
    return { ...row, ...row.user };
  },
  customSort: (data: any): SortOrder[] => {
    if (data.field === 'user') {
      return [
        {
          field: 'firstName',
          direction: data.direction,
        },
        {
          field: 'lastName',
          direction: data.direction,
        },
      ];
    }
    return [
      {
        field: data.field,
        direction: data.direction,
      },
    ];
  },
};
