import React, { useContext } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';

import { Messages, Notifications, SignOutAction, Settings } from '../actions';
import { ThemeSwitcher } from './ThemeSwitcher';
import { ThemeModeContext } from '../../falconeer/ThemeModeContext';
import { AppContext } from '../../falconeer/AppContext';

interface MobileMenuProps {
  isMenuOpen: boolean;
  handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
  anchorEl: HTMLElement | null;
}

export const MobileMenu = ({ isMenuOpen, handleMenuOpen, handleMenuClose, anchorEl }: MobileMenuProps) => {
  const { toggleThemeMode } = useContext(ThemeModeContext);
  const context = useContext(AppContext);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id='primary-search-account-menu-mobile'
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <Box sx={{ textAlign: 'center' }}>
        <MenuItem onClick={toggleThemeMode}>
          <ThemeSwitcher disableTooltip />
          Toggle Theme
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Messages total={15} disableTooltip />
          Messages
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Notifications total={20} disableTooltip />
          Notifications
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <Settings disableTooltip />
          Settings
        </MenuItem>
        <MenuItem onClick={context.signOut}>
          <SignOutAction disableTooltip />
          Sign Out
        </MenuItem>
      </Box>
    </Menu>
  );
};
