import { Button, StyledEngineProvider } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext, useRef } from 'react';
import { useState } from 'react';
import { CustomDialog } from '../../styles/CommonStyles';
import { useModalOpen } from './ConfirmationModalContextProvider';
import '../../styles/CommonColors.css';
import './MessageContextProvider.css';

type ModalContextType = {
  success: (message: string, title?: string) => Promise<boolean>;
  error: (message: string, title?: string) => Promise<boolean>;
};

type MessageModalContextProviderProps = {
  children: React.ReactNode;
};

const MessageModalContext = React.createContext<ModalContextType>({} as ModalContextType);

const MessageModalContextProvider: React.FC<MessageModalContextProviderProps> = props => {
  const { setOpen, open } = useModalOpen();
  const [content, setContent] = useState<{ message: string; title?: string } | null>();
  const resolver = useRef<Function>();
  const [isError, setIsError] = useState(false);

  const handleSuccess = (message: string, title: string = 'Success'): Promise<boolean> => {
    setContent({
      message,
      title,
    });
    setIsError(false);
    setOpen(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const handleError = (message: string, title: string = 'Error'): Promise<boolean> => {
    setContent({
      message,
      title,
    });
    setOpen(true);
    setIsError(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    success: handleSuccess,
    error: handleError,
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    setOpen(false);
  };

  return (
    <MessageModalContext.Provider value={modalContext}>
      {props.children}

      {content && (
        <CustomDialog open={open} disableEscapeKeyDown>
          <StyledEngineProvider injectFirst>
            <DialogTitle sx={{ m: 0, p: 2 }} className={isError ? 'error-color-code' : ''}>
              {content.title}
            </DialogTitle>
          </StyledEngineProvider>
          <DialogContent dividers className='modal-content'>
            {content.message}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOk}>OK</Button>
          </DialogActions>
        </CustomDialog>
      )}
    </MessageModalContext.Provider>
  );
};

const useMessageModalContext = (): ModalContextType => useContext(MessageModalContext);

export { useMessageModalContext };

export default MessageModalContextProvider;
