import { Box, styled } from '@mui/material';

export const FSectionTitle = styled(Box)(() => {
  return {
    color: '#030A73',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 550,
    margin: '20px 20px 20px 20px',
  };
}) as typeof Box;
