import { updatePatient } from '../api/core-graphql/core-mutation';
import { getPatient, listPatients } from '../api/core-graphql/core-queries';
import { CreatePatientInput, Patient, UpdatePatientInput } from '../api/CoreAPI';
import CoreAPIClient, { QueryDescriptors } from '../api/CoreAPIClient';

const queryDescriptors: QueryDescriptors = {
  getOne: {
    query: getPatient,
    responseAttribute: 'getPatient',
  },
  update: {
    query: updatePatient,
    responseAttribute: 'updatePatient',
  },
  getAll: {
    query: listPatients,
    responseAttribute: 'listPatients',
  },
  create: null,
  delete: null,
};

class PatientService extends CoreAPIClient<Patient, CreatePatientInput, UpdatePatientInput> {
  constructor() {
    super('User', queryDescriptors);
  }
}

const patientService = new PatientService();
Object.freeze(patientService);

export default patientService;
