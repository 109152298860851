import { Suspense } from 'react';
import { Box } from '@mui/material';

import { DrawerMenu } from '../drawer-menu';
import { AppHeader } from '../app-header';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import routes from '../../routes';
import ErrorBoundary from '../ErrorBoundary';
import ConfirmationModalContextProvider from '../dialog/ConfirmationModalContextProvider';
import MessageModalContextProvider from '../dialog/MessageContextProvider';
import { ContentWrapper, LayoutWrapper } from '../../styles/CommonStyles';
import { useEffect, useState } from 'react';

const SECURED_ROUTES = routes.filter(route => route.type === 'secured');
const routePaths = SECURED_ROUTES.map(route => route.path);

export const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(location?.state ? location.state : {});

  useEffect(() => {
    const currentUser = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')!) : null;
    if (!currentUser) {
      navigate('/');
      return;
    } else if (location && routePaths.length > 0 && routePaths.includes(location.pathname) === false) {
      navigate('/tasks');
      return;
    }
    const getUserDetails = () => {
      if (location && location.state) {
        setUser(location.state);
      }
    };
    getUserDetails();
  }, [location, navigate]);

  const signOut = () => {
    window.localStorage.clear();
    window.location.replace('/');
  };
  return (
    <LayoutWrapper>
      <MessageModalContextProvider>
        <ConfirmationModalContextProvider>
          <ContentWrapper>
            <DrawerMenu signOut={signOut} />
            <ErrorBoundary>
              <Box component='main' sx={{ flexGrow: 1 }}>
                <AppHeader signOut={signOut} user={user} />
                <Box sx={{ height: '99%' }}>
                  <Box sx={{ margin: 2 }}>
                    <Suspense fallback='Loading...'>
                      <Outlet />
                    </Suspense>
                  </Box>
                </Box>
              </Box>
            </ErrorBoundary>
          </ContentWrapper>
        </ConfirmationModalContextProvider>
      </MessageModalContextProvider>
    </LayoutWrapper>
  );
};
