import { Chip, emphasize, styled } from '@mui/material';

export const FBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: '#034273',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
      cursor: 'pointer',
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
    '& .MuiChip-label': {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    clickable: true,
  };
}) as typeof Chip;
