import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { Suspense, useEffect } from 'react';
import { useStore } from './falconeer/FalconStore';
import routes from './routes';
import ErrorBoundary from './components/ErrorBoundary';

const useStyles = makeStyles({
  containerStyles: {
    background: '#fff',
    padding: '40px 0',
    height: '100%',
    minHeight: '100vh',
  },
});

const UNSECURED_ROUTES = routes.filter(route => route.type === 'unsecured');
const routePaths = UNSECURED_ROUTES.map(route => route.path);
export const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const falconStore = useStore();

  useEffect(() => {
    const currentUser = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')!) : null;

    if (location && routePaths.length > 0) {
      if (routePaths.includes(location.pathname) === false) {
        navigate('/');
      } else if (currentUser) {
        navigate('/tasks');
      }
    }
  }, [location, falconStore.authUser, navigate]);

  const classes = useStyles();
  return (
    <Container maxWidth={false} disableGutters className={classes.containerStyles}>
      <ErrorBoundary>
        <Suspense fallback='Loading...'>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </Container>
  );
};
