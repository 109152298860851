import { Button, styled } from '@mui/material';
import { TableColumn } from './types';

function descendingComparator<T>(a: any, b: any, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator(order: Order, orderBy: TableColumn): (a: any, b: any) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy.attributeName)
    : (a, b) => -descendingComparator(a, b, orderBy.attributeName);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export const ActionButton = styled(Button)({ margin: '0.5rem' });

export const getPageRange = (totalItems: number, itemsPerPage: number, currentPage: number) => {
  let pageRange;
  if (currentPage === 1) {
    pageRange = totalItems < itemsPerPage ? totalItems : itemsPerPage;
  } else {
    pageRange = totalItems > itemsPerPage ? totalItems : itemsPerPage;
  }
  return pageRange;
};
