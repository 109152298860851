export default class AuthUser {
  public token: string | null = null;
  constructor(
    public id: string,
    public username: string,
    public email: string,
    public phone_number: string,
    public email_verified: boolean,
    public phone_number_verified: boolean,
    public groups: string[], // public clientId: string, //public token: string, // public userPoolId: string,
    public firstName: string,
    public lastName: string,
    public tenantName: string,
  ) {}

  isUserInGroups(groups: string[]) {
    if (groups.length === 0) return false;
    return this.groups.some(group => group.includes(group));
  }
}
