import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useConfirmationModalContext } from '../dialog/ConfirmationModalContextProvider';
import { useMessageModalContext } from '../dialog/MessageContextProvider';
import { FSecondaryButton } from '../fcomponents';
import EntityTable from './EntityTable';
import { EntityConfig } from './types';

type EntityManagerProps = {
  config: EntityConfig;
  jsonFormrenderer?: any;
};

export const EntityManager: React.FC<EntityManagerProps> = (props: EntityManagerProps) => {
  const [addEditOperation, setAddEditOperation] = useState<'add' | 'details' | 'edit' | 'none'>('none');
  const [data, setData] = useState<any>({});
  const [oldData, setOldData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const confirmDialogContext = useConfirmationModalContext();
  const dialogContext = useMessageModalContext();
  async function actionHandler(action: string, payload: any | null) {
    switch (action) {
      case 'add':
        {
          let initialData = {};
          setData(initialData);
          setOldData(initialData);
          setAddEditOperation('add');
          props.config.manageInput && props.config.manageInput({});
        }
        break;
      case 'edit':
        setAddEditOperation('edit');
        props.config.isDetailsView = false;
        if (payload) {
          props.config.manageInput && props.config.manageInput(payload);
          setData(payload);
          setOldData(payload);
        }
        break;
      case 'details':
        setAddEditOperation('details');
        props.config.isDetailsView = true;
        if (payload) {
          setData(payload);
        }
        break;
      case 'copy':
        {
          const result = await confirmDialogContext.showConfirmation('Are you sure you want to copy?');
          if (!result) {
            return;
          }
          // if (!confirm('Copy?')) return;
          if (!props.config.createCopy) return;
          const copyObject = props.config.createCopy(payload);
          setData(copyObject);
          setAddEditOperation('add');
        }
        break;
      case 'delete':
        {
          const result = await confirmDialogContext.showConfirmation('Are you sure you want to delete?');
          if (!result) {
            return;
          }
          // if (!confirm('Delete?')) return;
          await props.config.actionHandler(action, payload);
        }
        break;
      default:
        await props.config.actionHandler(action, payload);
    }
  }

  const dialogBoxActionHandler = async (payload: any, oldPayload: any) => {
    const result = await confirmDialogContext.showConfirmation('Are you sure you want to save the data?');
    if (!result) {
      return;
    }
    if (addEditOperation === 'add') {
      await props.config.actionHandler('add', payload);
    } else if (addEditOperation === 'edit') {
      await props.config.actionHandler('update', payload, oldPayload);
      props.config.manageInput && props.config.manageInput({});
    }
    setAddEditOperation('none');
  };

  async function addOrEditActionHandler(payload: any, oldPayload: any) {
    if (errors && errors.length > 0) {
      dialogContext.error('Please fill all the mandatory fields.');
      return;
    } else if (errors.length === 0 && props?.config?.validatePayload) {
      let validatePayloadResponse = props?.config?.validatePayload(payload);
      if (validatePayloadResponse.length > 0) {
        dialogContext.error(validatePayloadResponse.join('\n'));
      } else if (validatePayloadResponse.length === 0) {
        dialogBoxActionHandler(payload, oldPayload);
      }
    } else {
      dialogBoxActionHandler(payload, oldPayload);
    }
  }

  async function cancelActionHandler() {
    const result = await confirmDialogContext.showConfirmation('Are you sure you want to discard the changes?');
    if (!result) {
      return;
    }
    // if (!confirm('Are you sure you want to discard the changes?')) return;
    setAddEditOperation('none');
  }

  async function closeActionHandler() {
    setAddEditOperation('none');
  }

  const tableTitle = () => {
    switch (addEditOperation) {
      case 'add':
        return 'Add';
      case 'edit':
        return 'Edit';
      case 'details':
        return '';
      default:
        return '';
    }
  };

  return (
    <>
      {addEditOperation === 'none' && <EntityTable config={props.config} actionHandler={actionHandler} />}
      {addEditOperation != 'none' && (
        <>
          <Box
            sx={{
              display: 'flex',
              p: 1,
              flexDirection: 'row',
              bgcolor: 'background.paper',
              justifyContent: 'space-around',
              borderRadius: '0.3em',
              marginBottom: '0.2em',
            }}>
            <Typography variant='h6'>
              {tableTitle()} {props.config.entityName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 1,
              bgcolor: 'background.paper',
              marginBottom: '0.2em',
              borderRadius: '0.3em',
              width: '100%',
            }}>
            <JsonForms
              schema={props.config.formSchema}
              uischema={props.config.uiSchema}
              data={data}
              renderers={props.jsonFormrenderer ? props.jsonFormrenderer : materialRenderers}
              cells={materialCells}
              readonly={addEditOperation == 'details'}
              onChange={({ data, errors }) => {
                setData(data);
                setErrors(errors);
              }}
            />
            <Stack spacing={2} direction='row'>
              {addEditOperation !== 'details' && (
                <Button variant='contained' color='primary' onClick={() => addOrEditActionHandler(data, oldData)}>
                  Save
                </Button>
              )}
              {addEditOperation !== 'details' && (
                <FSecondaryButton variant='outlined' onClick={() => cancelActionHandler()}>
                  Cancel
                </FSecondaryButton>
              )}
              {addEditOperation === 'details' && (
                <FSecondaryButton variant='outlined' onClick={() => closeActionHandler()}>
                  Close
                </FSecondaryButton>
              )}
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};
