import { Drawer, DrawerHeader } from '../../styles/CommonStyles';
import { MenuList } from './MenuList';
import { useState } from 'react';
import { Divide as HamburgerMenu } from 'hamburger-react';
import { WHITE_COLOR } from '../fcomponents/ColorCodes';

interface NavigationProps {
  signOut: any;
}

export const DrawerMenu = ({ signOut }: NavigationProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const toggleNavigation = () => setOpen(status => !status);
  return (
    <Drawer variant='permanent' open={open}>
      <DrawerHeader>
        <Hamburger toggleNavigation={toggleNavigation} />
      </DrawerHeader>
      <MenuList signOut={signOut} />
    </Drawer>
  );
};

interface HamburgerProps {
  toggleNavigation: () => void;
}

const Hamburger = ({ toggleNavigation }: HamburgerProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
    toggleNavigation();
  };

  return <HamburgerMenu size={24} onToggle={toggle} color={WHITE_COLOR} toggled={isOpen} />;
};
