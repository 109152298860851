import { Task, CreateTaskInput, UpdateTaskInput } from '../api/CoreAPI';
import CoreAPIClient, { QueryDescriptors } from '../api/CoreAPIClient';
import { updateTask, createTask, deleteTask } from '../api/core-graphql/core-mutation';
import { getTask, listTasks } from '../api/core-graphql/core-queries';

const queryDescriptors: QueryDescriptors = {
  getOne: {
    query: getTask,
    responseAttribute: 'getTask',
  },
  update: {
    query: updateTask,
    responseAttribute: 'updateTask',
  },
  getAll: {
    query: listTasks,
    responseAttribute: 'listTasks',
  },
  create: {
    query: createTask,
    responseAttribute: 'createTask',
  },
  delete: {
    query: deleteTask,
    responseAttribute: 'deleteTask',
  },
};

class TaskService extends CoreAPIClient<Task, CreateTaskInput, UpdateTaskInput> {
  constructor() {
    super('Task', queryDescriptors);
  }
}

const taskService = new TaskService();
Object.freeze(taskService);

export default taskService;
