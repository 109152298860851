import { styled, TableRow } from '@mui/material';

export const FTableRow = styled(TableRow)(({ theme }) => ({
  background: '#FFFFFF',
  '&:hover': {
    background: '#e7eaec !important',
  },

  '& td': {
    alignItems: 'center',
    borderColor: 'transparent',
    '&:first-child': {
      borderTopLeftRadius: '30px',
      borderBottomLeftRadius: '30px',
    },
    '&:last-child': {
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
    },
  },
  '& th': {
    alignItems: 'center',
    '&:first-child': {
      borderTopLeftRadius: '30px',
      borderBottomLeftRadius: '30px',
    },
    '&:last-child': {
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
    },
  },
}));
