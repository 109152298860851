import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, DialogActions, DialogContent, DialogTitle, ListItemIcon, ListItemText, Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { Route } from '../../types';
import { CustomDialog, StyledListItemButton, StyledNavLink } from '../../styles/CommonStyles';
import './MenuItem.css';
import { UnsavedContext } from '../../App';
import { falconeerConst } from '../../utils/FalconeerConst';

interface MenuItemProps {
  menu: Route;
  nested?: boolean;
  hasChildren?: boolean;
  handleMenuClick?: (menu: Route) => void;
}

export const MenuItem = ({ menu, nested = false, hasChildren = false, handleMenuClick = () => {} }: MenuItemProps) => {
  const location = useLocation();
  const unsaveContext = useContext(UnsavedContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const UnSaveModal = () => {
    return (
      <CustomDialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent className='unsave-modal-content' dividers>
          {falconeerConst.unSavedContent}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              navigate(menu.path);
              unsaveContext.setUnsavedPageData(false);
            }}>
            Discard
          </Button>
          <Button
            onClick={() => {
              unsaveContext.setUnsavedPageData(true);
              setOpen(false);
            }}
            className='confirmation-button'>
            Stay
          </Button>
        </DialogActions>
      </CustomDialog>
    );
  };

  const handleNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (unsaveContext.unsavedPageData) {
      e.preventDefault();
      setOpen(true);
    } else if (!menu.isEnabled || hasChildren) {
      e.preventDefault();
    }
  };

  const item = (
    <StyledListItemButton
      isenabled={menu.isEnabled ? 'true' : 'false'}
      className={location.pathname.includes(menu.path) ? 'selected-tab' : ''}
      sx={{
        pl: nested ? 3 : 1,
        marginBottom: '7px',
      }}
      onClick={() => handleMenuClick(menu)}>
      <ListItemIcon>
        {menu.icon && (
          <Avatar sx={{ width: 32, height: 32, background: 'white' }} alt={menu.title?.charAt(0)}>
            <menu.icon sx={{ color: 'black' }}></menu.icon>
          </Avatar>
        )}
      </ListItemIcon>
      <ListItemText primary={menu.title} sx={{ color: '#FFFFFF' }} />
      {hasChildren && (menu.expanded ? <ExpandLess /> : <ExpandMore />)}
    </StyledListItemButton>
  );

  return (
    <>
      {open && <UnSaveModal />}
      <StyledNavLink to={`${menu.path}`} key={menu.key} onClick={handleNavigate}>
        {item}
      </StyledNavLink>
    </>
  );
};
