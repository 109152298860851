import CoreAPIClient, { QueryDescriptors } from '../api/CoreAPIClient';
import { createDevice, updateDevice } from '../api/core-graphql/core-mutation';
import { getDevice, listDevices } from '../api/core-graphql/core-queries';
import { CreateDeviceInput, Device, UpdateDeviceInput } from '../api/CoreAPI';

const queryDescriptors: QueryDescriptors = {
  getOne: {
    query: getDevice,
    responseAttribute: 'getDevice',
  },
  update: {
    query: updateDevice,
    responseAttribute: 'updateDevice',
  },
  getAll: {
    query: listDevices,
    responseAttribute: 'listDevices',
  },
  create: {
    query: createDevice,
    responseAttribute: 'createDevice',
  },
  delete: null,
};

class DeviceService extends CoreAPIClient<Device, CreateDeviceInput, UpdateDeviceInput> {
  constructor() {
    super('Device', queryDescriptors);
  }
}

const deviceService = new DeviceService();
Object.freeze(deviceService);

export default deviceService;
