import { Button, styled } from '@mui/material';
import { WHITE_COLOR, PRIMARY_COLOR, SECONDARY_GRAY } from './ColorCodes';

export const FPrimaryButton = styled(Button)(() => {
  return {
    backgroundColor: PRIMARY_COLOR,
    color: WHITE_COLOR,
    // width: '6em',
    borderRadius: '0.5em',
    float: 'right',
    display: 'inline',
    '&:hover, &:focus': {
      backgroundColor: PRIMARY_COLOR,
    },
  };
}) as typeof Button;

export const FSecondaryButton = styled(Button)(() => {
  return {
    color: SECONDARY_GRAY,
  };
}) as typeof Button;
