import {
  alpha,
  Dialog,
  InputBase,
  styled,
  Typography,
  Drawer as MuiDrawer,
  ListItemButton,
  IconButton,
  lighten,
  Icon,
  AppBar,
  Theme,
  CSSObject,
  TableRow,
} from '@mui/material';
import { ComponentType } from 'react';
import { NavLink } from 'react-router-dom';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../utils/constants';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: '#034273',
  border: '#034273',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  background: '#034273',
  border: '#034273',
});

export const HeaderWrapper = styled('div')(
  ({ theme }) => `
    flex: 1;
    display: flex;
    minHeight: ${HEADER_HEIGHT};
    margin-top: -26px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 3px;
`,
);

export const SearchWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

export const FooterWrapper = styled('div')(
  ({ theme }) => `
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    background: ${theme.palette.background.paper};
    minHeight: ${FOOTER_HEIGHT};
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 0 20%;
`,
);

export const FooterText = styled(Typography)`
  word-spacing: 0.1rem;
`;

export const StyledAppTitle = styled(Typography)`
  display: {
    xs: none;
    sm: block;
  }
  cursor: default;
`;

export const LayoutWrapper = styled('div')`
  min-height: 100vh;
`;

export const ContentWrapper = styled('div')`
  display: flex;
  min-height: calc(100vh - ${FOOTER_HEIGHT}px);
`;

// const DrawerHeader = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 1),
//   ...theme.mixins.toolbar,
// }));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: '500px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    minWidth: '500px',
  },
}));

export const GenerateReportDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: '600px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    minWidth: '600px',
  },
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
})) as any;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

export const StyledListItemButton = styled(ListItemButton)<{ isenabled: string }>(({ theme, isenabled }) =>
  isenabled === 'false'
    ? {
        '*': {
          cursor: 'not-allowed',
          color: theme.palette.text.secondary,
          '&:hover': {
            background: '#868686',
            borderRadius: '50px',
          },
        },
      }
    : {
        '&:hover': {
          background: '#868686',
          borderRadius: '50px',
        },
      },
);

export const StyledIconButton = styled(IconButton)<{ isselected: string }>(({ isselected, theme }) => ({
  boxShadow: isselected === 'true' ? `0 0 0 2px ${lighten(theme.palette.primary.main, 0.6)}` : 'default',
  transition: 'box-shadow 0.1s',
}));

export const StyledIcon = styled(Icon)<{ isselected: string; component: ComponentType<{}> }>`
  ${({ isselected, theme }) => (isselected === 'true' ? `color: ${theme.palette.primary.main};` : '')}
`;

export const StyledListItemButtonItem = styled(ListItemButton)`
  position: absolute;
  bottom: 0;
  width: 100%;
  color: '#FFFFFF',
  '&:hover': {
   background: '#868686',
   border-radius: '50px'
  },
`;

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: '#8592A4',
  height: '50px',
  minHeight: '50px',
  color: '#000000',
  boxShadow: 'none',
}));

export const StyledSearch = styled('div')(({ theme }) => ({
  background: '#FFFFF',
  borderRadius: '25px',
  position: 'relative',
  backgroundColor: alpha(theme.palette.common.white, 1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 1),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const CloseIconWrapper = styled('div')(({ theme }) => ({
  padding: '0 5px',
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
}));

export const SearchBoxWrapper = styled('div')(({ theme }) => ({
  display: 'contents',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const StyledTableHeader = styled(TableRow)(({ theme }) => ({
  '& td': {
    alignItems: 'center',
  },
  '& th': {
    alignItems: 'center',
    borderBottom: '1px solid #d7dadc',
  },
}));
