import { useState } from 'react';
import { List, Divider, Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import { MenuItem } from './MenuItem';

import routes from '../../routes';

import { Route } from '../../types';
import './MenuItem.css';

export const MenuList = ({ signOut }: any) => {
  const [menusState, setMenusStage] = useState<Route[]>(routes);
  const handleMenuClick = (menu: Route) => {
    const items = menusState.map(item => {
      if (item.key === menu.key) {
        item.expanded = !item.expanded;
      }
      return item;
    });
    setMenusStage(items);
  };

  return (
    <>
      <List component='nav' className='tab-highlighter-padding'>
        <Box component='span' sx={{ display: 'block' }}>
          {menusState.map(
            (menu: Route) =>
              menu.isMenu && (
                <div key={menu.key}>
                  {menu.subRoutes ? (
                    <>
                      <MenuItem key={`${menu.key}`} menu={menu} hasChildren handleMenuClick={handleMenuClick} />
                      <Collapse in={menu.expanded} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                          {menu.subRoutes.map((sRoute: Route) => (
                            <MenuItem key={`${sRoute.key}`} menu={sRoute} nested />
                          ))}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <MenuItem key={menu.key} menu={menu} nested={false} />
                  )}
                  {menu.appendDivider && <Divider />}
                </div>
              ),
          )}
        </Box>
      </List>
    </>
  );
};
