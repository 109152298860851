import { gql } from '@apollo/client';

export const createMeasurement = gql`
  mutation CreateMeasurement($input: CreateMeasurementInput!) {
    createMeasurement(input: $input) {
      dataPrecision
      defaultTarget
      description
      displayName
      id
      indicator
      name
      tags
      type
      unit
    }
  }
`;

export const updateMeasurement = gql`
  mutation UpdateMeasurement($id: String!, $input: UpdateMeasurementInput!) {
    updateMeasurement(id: $id, input: $input) {
      dataPrecision
      defaultTarget
      description
      displayName
      id
      indicator
      name
      tags
      type
      unit
    }
  }
`;

export const deleteMeasurement = gql`
  mutation DeleteMeasurement($id: String!) {
    deleteMeasurements(id: $id) {
      displayName
      alertAbove
      alertBelow
      dataPrecision
      defaultTarget
      description
      displayType
      indicator
      maxValue
      minValue
      name
      type
      unit
    }
  }
`;
export const createSymptom = gql`
  mutation CreateSymptom($input: CreateSymptomInput!) {
    createSymptom(input: $input) {
      name
      displayName
    }
  }
`;
export const deleteSymptom = gql`
  mutation DeleteSymptom($id: String!) {
    deleteSymptom(id: $id) {
      id
      name
      displayName
    }
  }
`;
export const updateSymptom = gql`
  mutation UpdateSymptom($id: String!, $input: UpdateSymptomInput!) {
    updateSymptom(id: $id, input: $input) {
      id
      name
      displayName
    }
  }
`;
export const createContent = gql`
  mutation CreateContent($input: CreateContentInput!) {
    createContent(input: $input) {
      id
      tags
      title
      type
      value
    }
  }
`;
export const updateContent = gql`
  mutation UpdateContent($id: String!, $input: UpdateContentInput!) {
    updateContent(id: $id, input: $input) {
      id
      tags
      title
      type
      value
    }
  }
`;
export const deleteContent = gql`
  mutation DeleteContent($id: String!) {
    deleteContent(id: $id) {
      id
      tags
      title
      type
      value
    }
  }
`;

export const createTimeTracking = gql`
  mutation CreateTimeTracking($input: CreateTimeTrackingInput!) {
    createTimeTracking(input: $input) {
      createdAt
      doctorId
      duration
      patientId
    }
  }
`;
export const createDevice = gql`
  mutation CreateDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      category
      createdAt
      displayName
      id
      managedBy
      model
      name
      type
      updatedAt
    }
  }
`;
export const updateDevice = gql`
  mutation UpdateDevice($id: String!, $input: UpdateDeviceInput!) {
    updateDevice(id: $id, input: $input) {
      category
      createdAt
      displayName
      id
      managedBy
      measurements {
        id
        name
      }
      model
      name
      type
      updatedAt
    }
  }
`;
export const createPatientDevice = gql`
  mutation CreatePatientDevice($input: CreatePatientDeviceInput!) {
    createPatientDevice(input: $input) {
      id
    }
  }
`;
export const createCondition = gql`
  mutation CreateCondition($input: CreateConditionInput!) {
    createCondition(input: $input) {
      id
    }
  }
`;
export const updatePatientDevice = gql`
  mutation UpdatePatientDevice($id: String!, $input: UpdatePatientDeviceInput!) {
    updatePatientDevice(id: $id, input: $input) {
      id
    }
  }
`;

export const createDocumentation = gql`
  mutation CreateDocumentation($input: CreateDocumentationInput!) {
    createDocumentation(input: $input) {
      capturedAt
      capturedById
      createdAt
      id
      note
      patientId
      reviewedAt
      reviewedById
      status
      updatedAt
    }
  }
`;

export const updateCondition = gql`
  mutation UpdateCondition($id: String!, $input: UpdateConditionInput!) {
    updateCondition(id: $id, input: $input) {
      id
    }
  }
`;
export const deletePatientDevice = gql`
  mutation DeletePatientDevice($id: String!) {
    deletePatientDevice(id: $id) {
      id
    }
  }
`;

export const updateDocumentation = gql`
  mutation UpdateDocumentation($id: String!, $input: UpdateDocumentationInput!) {
    updateDocumentation(id: $id, input: $input) {
      id
    }
  }
`;

export const deleteDocumentation = gql`
  mutation DeleteDocumentation($id: String!) {
    deleteDocumentation(id: $id) {
      id
    }
  }
`;

export const deleteCondition = gql`
  mutation DeleteCondition($id: String!) {
    deleteCondition(id: $id) {
      id
    }
  }
`;
export const updateTask = gql`
  mutation UpdateTask($id: String!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      id
    }
  }
`;
export const createTask = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
    }
  }
`;
export const deleteTask = gql`
  mutation DeleteTask($id: String!) {
    deleteTask(id: $id) {
      id
    }
  }
`;

export const createPatientSymptom = gql`
  mutation CreatePatientSymptom($input: CreatePatientSymptomInput!) {
    createPatientSymptom(input: $input) {
      createdAt
      data
      id
      patientId
      symptomId
      updatedAt
    }
  }
`;

export const updatePatientSymptom = gql`
  mutation UpdatePatientSymptom($id: String!, $input: UpdatePatientSymptomInput!) {
    updatePatientSymptom(id: $id, input: $input) {
      createdAt
      data
      id
      patientId
      symptomId
      updatedAt
    }
  }
`;
export const deletePatientSymptom = gql`
  mutation DeletePatientSymptom($id: String!) {
    deletePatientSymptom(id: $id) {
      createdAt
      data
      id
      patientId
      symptomId
      updatedAt
    }
  }
`;
export const createInvitedUser = gql`
  mutation CreateInvitedUsers($input: CreateInvitedUserInput!) {
    createInvitedUsers(input: $input) {
      id
    }
  }
`;

export const updateInvitedUser = gql`
  mutation UpdateInvitedUser($id: String!, $input: UpdateInvitedUserInput!) {
    updateInvitedUser(id: $id, input: $input) {
      id
    }
  }
`;

export const deleteInvitedUsers = gql`
  mutation DeleteInvitedUsers($id: String!) {
    deleteInvitedUsers(id: $id) {
      firstName
      lastName
    }
  }
`;

export const updateUser = gql`
  mutation UpdateUser($id: String!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      id
    }
  }
`;

export const updatePatient = gql`
  mutation UpdateUser($id: String!, $input: UpdatePatientInput!) {
    updatePatient(id: $id, input: $input) {
      id
    }
  }
`;

export const createPatientCondition = gql`
  mutation CreatePatientCondition($input: CreatePatientConditionInput!) {
    createPatientCondition(input: $input) {
      id
    }
  }
`;

export const updatePatientCondition = gql`
  mutation UpdatePatientCondition($id: String!, $input: UpdatePatientConditionInput!) {
    updatePatientCondition(id: $id, input: $input) {
      id
    }
  }
`;

export const deletePatientCondition = gql`
  mutation DeletePatientCondition($id: String!) {
    deletePatientCondition(id: $id) {
      conditionId
    }
  }
`;

export const createPatientMeasurement = gql`
  mutation CreatePatientMeasurement($input: CreatePatientMeasurementInput!) {
    createPatientMeasurement(input: $input) {
      id
    }
  }
`;

export const updatePatientMeasurement = gql`
  mutation UpdatePatientMeasurement($id: String!, $input: UpdatePatientMeasurementInput!) {
    updatePatientMeasurement(id: $id, input: $input) {
      id
    }
  }
`;
export const deletePatientMeasurements = gql`
  mutation DeletePatientMeasurements($id: String!) {
    deletePatientMeasurements(id: $id) {
      updatedAt
    }
  }
`;

export const createPatientPrescribedDevice = gql`
  mutation CreatePatientPrescribedDevice($input: CreatePatientPrescribedDeviceInput!) {
    createPatientPrescribedDevice(input: $input) {
      id
    }
  }
`;
export const updatePatientPrescribedDevice = gql`
  mutation UpdatePatientPrescribedDevice($id: String!, $input: UpdatePatientPrescribedDeviceInput!) {
    updatePatientPrescribedDevice(id: $id, input: $input) {
      comment
      id
    }
  }
`;

export const createSharedDevice = gql`
  mutation CreateSharedDevice($input: CreateSharedDeviceInput!) {
    createSharedDevice(input: $input) {
      createdAt
      deviceId
      id
      label
      hardwareId
      appDevice {
        hardwareName
      }
      device {
        displayName
      }
    }
  }
`;

export const updateSharedDevice = gql`
  mutation UpdateSharedDevice($id: String!, $input: UpdateSharedDeviceInput!) {
    updateSharedDevice(id: $id, input: $input) {
      createdAt
      deviceId
      id
      label
      updatedAt
      hardwareId
      appDevice {
        hardwareName
      }
      device {
        displayName
      }
    }
  }
`;

export const deleteSharedDevice = gql`
  mutation DeleteSharedDevice($id: String!) {
    deleteSharedDevice(id: $id) {
      label
    }
  }
`;
