import { createTheme, responsiveFontSizes } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { APPLICATION_BACKGROUND, PRIMARY_HEADER_COLOR } from '../components/fcomponents/ColorCodes';

import { DARK_MODE_THEME, LIGHT_MODE_THEME } from '../utils/constants';

export const getAppTheme = (mode: typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME) => {
  let theme = createTheme({
    // palette: {
    //   mode,
    // },
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            primary: blue,
            divider: grey[200],
            background: {
              default: APPLICATION_BACKGROUND,
            },
            text: {
              primary: grey[900],
              secondary: grey[800],
            },
          }
        : {}),
    },
    typography: {
      fontFamily: 'Roboto',
      h1: {
        fontSize: 48,
        fontWeight: 600,
        lineHeight: 1.5,
        color: PRIMARY_HEADER_COLOR,
      },
      h2: {
        fontSize: 36,
        fontWeight: 600,
        lineHeight: 1.5,
        color: PRIMARY_HEADER_COLOR,
      },
      h3: {
        fontSize: 32,
        fontWeight: 600,
        lineHeight: 1.5,
        color: PRIMARY_HEADER_COLOR,
      },
      h4: {
        fontSize: 24,
        fontWeight: 600,
        lineHeight: 1.5,
        color: PRIMARY_HEADER_COLOR,
      },
      h5: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.5,
        color: PRIMARY_HEADER_COLOR,
      },
      h6: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.5,
        color: PRIMARY_HEADER_COLOR,
      },
      body1: {},
      body2: {},
      subtitle1: {
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: 1.75,
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: 1.57,
      },
      caption: {
        fontWeight: 500,
        color: PRIMARY_HEADER_COLOR,
      },
      overline: {
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 1,
        lineHeight: 1.66,
      },
      button: {
        fontWeight: 500,
        textTransform: 'none',
      },
    },
  });
  theme = responsiveFontSizes(theme);
  return theme;
};
