import { action, configure as mobxConfigure, makeAutoObservable } from 'mobx';
import AuthUser from './AuthUser';
import userService from '../services/UserService';
import { createContext, useContext } from 'react';
import HealthDataAPIClient from '../api/HealthDataAPIClient';
import { User } from '../api/CoreAPI';
import axios, { AxiosRequestConfig } from 'axios';
import { PRE_LOGIN_CONFIG_KEY_AUTH_URL, PRE_LOGIN_CONFIG_KEY_R_TOKEN } from '../utils/AppConfigKeys';
import { getAppConfigValue } from '../utils/Utils';

mobxConfigure({ enforceActions: 'never' });

export default class FalconStore {
  storeReady = false;
  lastRefreshTime: Date | null = null;
  authUser: AuthUser | null = null;
  userId: string | null = null;
  user: User | null = null;
  healthDataAPIClient;
  static instance: FalconStore | null = null;
  static getInstance() {
    if (!FalconStore.instance) FalconStore.instance = new FalconStore();
    return FalconStore.instance;
  }

  private constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.healthDataAPIClient = new HealthDataAPIClient(this.getToken);
  }

  @action
  clear() {
    this.authUser = null;
    this.user = null;
    this.userId = '';
    this.storeReady = false;
  }

  @action
  async init(authUser: AuthUser) {
    if (this.storeReady) return;
    this.authUser = authUser;
    this.userId = authUser.id;
    if (this.userId) {
      this.user = await userService.getOne({ id: this.userId });
    }
    this.storeReady = true;
  }

  isReady() {
    return this.storeReady;
  }

  getToken = async (refreshToken: boolean = false) => {
    //TODO this should ideally come from falconStore..
    if (refreshToken === false) {
      return window.localStorage.getItem('idToken');
    } else {
      //fetch new token
      let refreshTokenVal = window.localStorage.getItem('refreshToken')
        ? window.localStorage.getItem('refreshToken')
        : '';
      let user = window.localStorage.getItem('user');
      if (!user) {
        throw new Error('User not logged in');
      }
      const userObj = JSON.parse(user);
      const reqBody = { email: userObj?.email, refreshToken: refreshTokenVal };
      const options: AxiosRequestConfig<any> = {
        url: `${getAppConfigValue(PRE_LOGIN_CONFIG_KEY_AUTH_URL)}/${getAppConfigValue(PRE_LOGIN_CONFIG_KEY_R_TOKEN)}`,
        method: 'POST',
        data: reqBody,
      };
      try {
        return await axios.request(options).then((response: any) => {
          let responseData = response.data.data;
          window.localStorage.setItem('accessToken', responseData.accessToken);
          window.localStorage.setItem('idToken', responseData.idToken);
          window.localStorage.setItem('refreshToken', responseData.refreshToken);
          return responseData.idToken;
        });
      } catch (err) {
        window.localStorage.clear();
        window.location.replace('/');
      }
    }
  };
  @action
  getUser() {
    return this.authUser;
  }
  @action
  getUserGroups() {
    return this.authUser?.groups;
  }
}

export const falconStore = FalconStore.getInstance();
export const StoreContext = createContext(falconStore);
export const useStore = () => useContext(StoreContext);
