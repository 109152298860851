import { gql } from '@apollo/client';

export const listMeasurements = gql`
  query ListMeasurements($filter: MeasurementFilterInput, $sort: [SortOrder!]) {
    listMeasurements(filter: $filter, sort: $sort) {
      dataPrecision
      defaultTarget
      description
      displayName
      id
      indicator
      name
      tags
      type
      unit
      displayType
      maxValue
      minValue
      bucket
      conditions {
        acronym
        name
      }
    }
  }
`;

export const getMeasurement = gql`
  query GetMeasurement($id: String!) {
    getMeasurement(id: $id) {
      dataPrecision
      defaultTarget
      description
      displayName
      id
      indicator
      name
      tags
      type
      unit
      maxValue
      minValue
      displayType
      bucket
    }
  }
`;
export const getSymptom = gql`
  query GetSymptom($id: String!) {
    getSymptom(id: $id) {
      id
      name
      displayName
    }
  }
`;
export const listSymptoms = gql`
  query ListSymptoms($filter: SymptomFilterInput, $sort: [SortOrder!]) {
    listSymptoms(filter: $filter, sort: $sort) {
      id
      name
      displayName
      createdAt
    }
  }
`;

export const ListTemplates = gql`
  query ListTemplates($filter: TemplateFilterInput, $sort: [SortOrder!]) {
    listTemplates(filter: $filter, sort: $sort) {
      data
      id
      name
      subject
      tenantId
      type
    }
  }
`;

export const getContent = gql`
  query GetContent($id: ID!) {
    getContent(id: $id) {
      id
      tags
      title
      type
      value
    }
  }
`;
export const listContents = gql`
  query ListContents($filter: ContentFilterInput, $sort: [SortOrder!]) {
    listContents(filter: $filter, sort: $sort) {
      id
      tags
      title
      type
      value
    }
  }
`;
export const getTimeTrackingSummary = gql`
  query GetTimeTrackingSummary($doctorId: String!, $patientId: String!) {
    getTimeTrackingSummary(doctorId: $doctorId, patientId: $patientId) {
      totalDuration
    }
  }
`;
export const listDevices = gql`
  query ListDevices($filter: DeviceFilterInput, $sort: [SortOrder!]) {
    listDevices(filter: $filter, sort: $sort) {
      category
      createdAt
      displayName
      id
      managedBy
      measurements {
        id
        displayName
        name
        type
      }
      model
      name
      type
      manufacturer
    }
  }
`;
export const getDevice = gql`
  query GetDevice($id: String!) {
    getDevice(id: $id) {
      category
      createdAt
      displayName
      id
      managedBy
      measurements {
        id
        displayName
        name
        type
      }
      model
      name
      type
      manufacturer
    }
  }
`;
export const getCondition = gql`
  query GetCondition($id: String!) {
    getCondition(id: $id) {
      acronym
      createdAt
      id
      name
      updatedAt
      description
      measurements {
        id
        displayName
        name
      }
      symptoms {
        id
        displayName
        name
      }
    }
  }
`;
export const listConditions = gql`
  query ListConditions($filter: ConditionFilterInput, $sort: [SortOrder!]) {
    listConditions(filter: $filter, sort: $sort) {
      acronym
      createdAt
      id
      name
      updatedAt
      description
      measurements {
        id
        displayName
        name
      }
      symptoms {
        id
        displayName
        name
      }
    }
  }
`;
export const getPatientDevice = gql`
  query GetPatientDevice($id: String!) {
    getPatientDevice(id: $id) {
      createdAt
      deviceId
      id
      patientId
      updatedAt
      device {
        category
        displayName
        id
        managedBy
        manufacturer
        model
        name
        type
      }
    }
  }
`;
export const listPatientDevices = gql`
  query ListPatientDevices($filter: PatientDeviceFilterInput) {
    listPatientDevices(filter: $filter) {
      createdAt
      deviceId
      id
      patientId
      updatedAt
      device {
        category
        displayName
        id
        managedBy
        manufacturer
        model
        name
        type
      }
    }
  }
`;
export const listTasks = gql`
  query ListTasks(
    $filter: TaskFilterInput!
    $sort: [SortOrder!]
    $search: SearchTaskInput
    $pagination: PaginationInput
  ) {
    listTasks(filter: $filter, sort: $sort, search: $search, pagination: $pagination) {
      items {
        completedAt
        completedById
        createdAt
        dueDate
        id
        patientId
        reason
        remarks
        status
        type
        updatedAt
        user {
          firstName
          lastName
        }
      }
      pagination
    }
  }
`;
export const getTask = gql`
  query GetTask($id: String!) {
    getTask(id: $id) {
      completedAt
      completedById
      createdAt
      dueDate
      id
      patientId
      reason
      remarks
      status
      type
      updatedAt
      patient {
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export const getPatientSymptom = gql`
  query GetPatientSymptom($id: String!) {
    getPatientSymptom(id: $id) {
      createdAt
      data
      id
      patientId
      symptomId
      updatedAt
    }
  }
`;

export const getDocumentation = gql`
  query GetDocumentation($id: String!) {
    getDocumentation(id: $id) {
      capturedAt
      capturedById
      createdAt
      id
      note
      patientId
      reviewedAt
      reviewedById
      status
    }
  }
`;

export const listDocumentations = gql`
  query ListDocumentation($filter: DocumentationFilterInput, $sort: [SortOrder!]) {
    listDocumentation(filter: $filter, sort: $sort) {
      capturedAt
      capturedById
      createdAt
      id
      note
      patientId
      reviewedAt
      reviewedById
      status
      updatedAt
      capturedBy {
        email
        firstName
        lastName
      }
      reviewedBy {
        email
        firstName
        lastName
      }
    }
  }
`;

export const listPatientSymptoms = gql`
  query ListPatientSymptoms($filter: PatientSymptomFilterInput!) {
    listPatientSymptoms(filter: $filter) {
      createdAt
      data
      id
      patientId
      symptomId
      updatedAt
      occuredAt
      symptom {
        displayName
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;
export const getInvitedUser = gql`
  query GetInvitedUser($id: String!) {
    getInvitedUser(id: $id) {
      address
      birthDate
      city
      createdAt
      data
      email
      firstName
      gender
      height
      id
      invitedByUserId
      joinedAt
      lastName
      phoneNumber
      state
      updatedAt
      weight
      zipCode
    }
  }
`;

export const listInvitedUsers = gql`
  query ListInvitedUsers(
    $filter: InvitedUserFilterInput
    $sort: [SortOrder!]
    $search: SearchUserInput
    $pagination: PaginationInput
  ) {
    listInvitedUsers(filter: $filter, sort: $sort, search: $search, pagination: $pagination) {
      items {
        address
        birthDate
        city
        createdAt
        data
        email
        firstName
        gender
        height
        id
        invitedByUserId
        joinedAt
        lastName
        phoneNumber
        state
        updatedAt
        weight
        zipCode
        timezone
        tenantId
        roleId
        country
        tenant {
          displayName
        }
        roles {
          name
        }
      }
      pagination
    }
  }
`;

export const listRole = gql`
  query ListRole($filter: RoleFilterInput, $sort: [SortOrder!]) {
    listRole(filter: $filter, sort: $sort) {
      id
      name
      updatedAt
    }
  }
`;

export const listTenants = gql`
  query listTenants($filter: TenantFilterInput, $sort: [SortOrder!]) {
    listTenants(filter: $filter, sort: $sort) {
      id
      displayName
    }
  }
`;

export const getUser = gql`
  query GetUser($id: String!) {
    getUser(id: $id) {
      address
      city
      createdAt
      data
      email
      firstName
      id
      isEnabled
      lastName
      phoneNumber
      state
      updatedAt
      zipCode
    }
  }
`;

export const listUsers = gql`
  query ListUsers($filter: UserFilterInput) {
    listUsers(filter: $filter) {
      address
      city
      createdAt
      data
      email
      firstName
      id
      isEnabled
      lastName
      phoneNumber
      state
      updatedAt
      zipCode
      timezone
    }
  }
`;

export const getPatient = gql`
  query GetPatient($id: String!) {
    getPatient(id: $id) {
      birthDate
      createdAt
      data
      gender
      height
      id
      updatedAt
      weight
      user {
        address
        city
        createdAt
        data
        email
        firstName
        id
        isEnabled
        lastName
        phoneNumber
        state
        updatedAt
        zipCode
        timezone
      }
      conditions {
        acronym
      }
    }
  }
`;

export const listPatients = gql`
  query ListPatients(
    $filter: PatientFilterInput
    $sort: [SortOrder!]
    $search: SearchInput
    $pagination: PaginationInput
  ) {
    listPatients(filter: $filter, sort: $sort, search: $search, pagination: $pagination) {
      items {
        birthDate
        createdAt
        data
        gender
        height
        id
        updatedAt
        weight
        user {
          address
          city
          createdAt
          data
          email
          firstName
          id
          isEnabled
          lastName
          phoneNumber
          state
          updatedAt
          zipCode
          timezone
          isConfirmed
        }
        conditions {
          acronym
        }
      }
      pagination
    }
  }
`;

export const getPatientCondition = gql`
  query GetPatientCondition($id: String!) {
    getPatientCondition(id: $id) {
      conditionId
      createdAt
      id
      patientId
      updatedAt
      condition {
        acronym
        description
        id
        name
      }
    }
  }
`;

export const listPatientCondition = gql`
  query ListPatientCondition($filter: PatientConditionFilterInput!) {
    listPatientCondition(filter: $filter) {
      conditionId
      createdAt
      id
      patientId
      updatedAt
      condition {
        acronym
        description
        id
        name
      }
    }
  }
`;
export const getPatientMeasurement = gql`
  query GetPatientMeasurement($id: String!) {
    getPatientMeasurement(id: $id) {
      alertAbove
      alertBelow
      id
      measurementId
      patientId
      targetValue
      measurement {
        createdAt
        dataPrecision
        defaultTarget
        description
        displayName
        displayType
        id
        indicator
        maxValue
        minValue
        name
        tags
        type
        unit
        updatedAt
      }
    }
  }
`;

export const listPatientMeasurements = gql`
  query ListPatientMeasurements($filter: PatientMeasurementFilterInput!, $sort: [SortOrder!]) {
    listPatientMeasurements(filter: $filter, sort: $sort) {
      alertAbove
      alertBelow
      createdAt
      id
      measurementId
      patientId
      targetValue
      updatedAt
      measurement {
        createdAt
        dataPrecision
        defaultTarget
        description
        displayName
        displayType
        id
        indicator
        maxValue
        minValue
        name
        tags
        type
        unit
        updatedAt
        bucket
      }
    }
  }
`;

export const listPatientPrescribedDevices = gql`
  query ListPatientPrescribedDevices($filter: PatientPrescribedDeviceFilterInput) {
    listPatientPrescribedDevices(filter: $filter) {
      comment
      createdAt
      updatedAt
      deviceId
      id
      patientId
      status
      deviceStatus
      orders {
        comment
        createdAt
        orderId
        status
      }
      device {
        category
        displayName
        manufacturer
        model
        name
        managedBy
      }
      patient {
        user {
          firstName
          lastName
          email
          address
        }
      }
      patientDevice {
        deviceInfo
      }
    }
  }
`;
export const getPatientPrescribedDevice = gql`
  query GetPatientPrescribedDevice($id: String!) {
    getPatientPrescribedDevice(id: $id) {
      comment
      createdAt
      deviceId
      id
      patientId
      status
      deviceStatus
      orders {
        comment
        createdAt
        orderId
        status
      }
      device {
        category
        displayName
        manufacturer
        model
        name
        managedBy
      }
      patient {
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export const listSharedDevice = gql`
  query ListSharedDevice($filter: SharedDeviceFilterInput, $pagination: PaginationInput) {
    listSharedDevice(filter: $filter, pagination: $pagination) {
      pagination
      items {
        id
        deviceId
        label
        updatedAt
        hardwareId
        appDevice {
          hardwareName
        }
        device {
          displayName
        }
      }
    }
  }
`;

export const getSharedDevice = gql`
  query GetSharedDevice($id: String!) {
    getSharedDevice(id: $id) {
      deviceId
      id
      label
      updatedAt
      hardwareId
      appDevice {
        hardwareName
      }
      device {
        displayName
      }
    }
  }
`;
