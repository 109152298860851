import { styled, TextField } from '@mui/material';
import { WHITE_COLOR } from './ColorCodes';

export const FTextField = styled(TextField)(({ bgcolor }: any) => {
  return {
    backgroundColor: bgcolor ? bgcolor : WHITE_COLOR,
    marginBottom: '30px',
    marginRight: '10px',
    width: '35%',
  };
}) as typeof TextField;
