// APP TEXT
export const APP_TITLE = 'Care Platform';
export const APP_DESCRIPTION = 'App for Falconeer superuser.';
export const COPYRIGHT_TEXT = `Copyright 2023 Falconeer, Inc. All rights reserved.`;
// PAGES TITLE
export const PAGE_TITLE_HOME = 'Home';
export const PAGE_TITLE_PROVIDERS = 'Providers';
export const PAGE_TITLE_PATIENTS = 'Patients';
export const PAGE_TITLE_PATIENT_DASHBOARD = 'Patient Dashboard';
export const PAGE_TITLE_MEASUREMENTS = 'Measurements';
export const PAGE_TITLE_DEVICES = 'Devices';
export const PAGE_TITLE_PRESCRIBED_DEVICES = 'Prescribed Devices';
export const PAGE_TITLE_CONDITIONS = 'Conditions';
export const PAGE_TITLE_DEVELOPER = 'Developer';
export const PAGE_TITLE_ENTITY_BROWSER = 'Entity Browser';
export const PAGE_TITLE_TASKS = 'Tasks';
export const PAGE_TITLE_SYMPTOMS = 'Symptoms';
export const PAGE_TITLE_IMPORTED_PATIENTS = 'Imported Patients';
export const PAGE_TITLE_CONTENT = 'Content';
export const PAGE_TITLE_ERROR = 'Error';
export const PAGE_TITLE_ONBOARD_USER = 'Onboard Users';
export const PAGE_TITLE_SHARED_DEVICE = 'Shared Device';
// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;
// APP THEME
export const DARK_MODE_THEME = 'dark';
export const LIGHT_MODE_THEME = 'light';
// ROLE CONSTANT
export const ADMIN = 'Admin';
export const FALCONEER_ADMIN = 'FalconeerAdmin';
export const DOCTOR = 'Doctor';

//URL CONSTANT
export const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
export const PRE_LOGIN_CONFIG = `${API_HOST_URL}config/v1/prelogin`;
export const POST_LOGIN_CONFIG = `${API_HOST_URL}config/v1/postlogin`;

export const GET_HEALTH_DATA_API_URL = `${API_HOST_URL}healthdata/v1/`;
export const CDS_FILTER_API_URL = `${API_HOST_URL}healthdata/v1/patients/`;
export const GET_PATIENT_REPORTED_EVENT_API_URL = `${API_HOST_URL}healthdata/v1/patient-symptoms`;

//LEFT MENU PATH CONSTANTS
export const PAGE_PATH_TASKS = '/tasks';
export const PAGE_PATH_PATIENTS = '/patients';
export const PAGE_PATH_IMPORTED_PATIENTS = '/importedPatients';
export const PAGE_PATH_PATIENT_DASHBOARD = '/patients/dashboard';
export const PAGE_PATH_DEVICES = '/devices';
export const PAGE_PATH_PRESCRIBED_DEVICES = '/prescribedDevice';
export const PAGE_PATH_MEASUREMENTS = '/measurements';
export const PAGE_PATH_CONDITIONS = '/Conditions';
export const PAGE_PATH_SYMPTOMS = '/symptoms';
export const PAGE_PATH_CONTENT = '/content';
export const PAGE_PATH_ERROR = '/error';
export const PAGE_PATH_ONBOARD_USER = '/onboardUser';
export const PAGE_PATH_SHARED_DEVICE = '/sharedDevice';
