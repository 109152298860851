import { Box, styled } from '@mui/material';
import { LIGHT_GRAY } from './ColorCodes';

export const FCard = styled(Box)(({ bgcolor }: any) => {
  return {
    backgroundColor: bgcolor ? bgcolor : LIGHT_GRAY,
    borderRadius: '10px',
    margin: '10px',
    height: '100%',
  };
}) as typeof Box;
