import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { CustomDialog, StyledAppBar } from '../../styles/CommonStyles';
import { useState } from 'react';
import { MobileMenu } from './MobileMenu';
import { DefaultMenu } from './DefaultMenu';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../falconeer/FalconStore';
import { useEffect } from 'react';
import { SignOutAction } from '../actions';
import { makeStyles } from '@mui/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { addTimeTrackingRecord } from '../timer-control/TimerControl';
import OverlayLoader from '../OverlayLoader';
import { User } from '../../api/CoreAPI';
import { PRE_LOGIN_CONFIG_KEY_S3URL } from '../../utils/AppConfigKeys';
import { getAppConfigValue } from '../../utils/Utils';
import { falconeerConst } from '../../utils/FalconeerConst';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    pt: 0.4,
    flexDirection: 'row',
    paddingTop: '0px',
  },
  OuterBox: {
    flexGrow: 1,
    justifyContent: 'space-between',
    xs: 'none',
    md: 'flex',
    display: 'flex',
  },
  FalconLogo: {
    flexGrow: 1,
    xs: 'none',
    sm: 'block',
    maxWidth: 35,
    height: 35,
    marginRight: '10px !important',
  },
  FalconTitle: {
    flexGrow: 1,
    xs: 'none',
    sm: 'block',
    maxWidth: 150,
    height: '19px',
    margin: 0.6,
  },
  WelcomeTitle: {
    alignSelf: 'center',
    float: 'right',
    color: '#ffffff',
  },
  SignOutButton: {
    marginLeft: '30px',
    float: 'right',
    color: '#ffffff',
  },
  alignment: {
    display: 'inline-flex',
  },
  logoAlign: {
    alignItems: 'center',
    display: 'inline-flex',
  },
});

interface HeaderProps {
  signOut: any;
  user: User;
}
export const AppHeader = ({ signOut, user }: HeaderProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation() as any;
  const [currentUser, setCurrentUser] = useState(location?.state ? location.state : {});
  const falconStore = useStore();
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setCurrentUser(falconStore.authUser);
  }, [falconStore.authUser]);

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    let totalDurationInSeconds = window.localStorage.getItem('unsavedDuration')
      ? parseInt(window.localStorage.getItem('unsavedDuration')!)
      : 0;
    let patientId = window.localStorage.getItem('unsavedDuration_patientId')
      ? window.localStorage.getItem('unsavedDuration_patientId')!
      : '';
    if (totalDurationInSeconds > 0) {
      setIsLoading(true);
      await addTimeTrackingRecord(totalDurationInSeconds, patientId, currentUser.id);
      setIsLoading(false);
    }
    window.localStorage.clear();
    window.location.replace('/');
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => setMobileMoreAnchorEl(event.currentTarget);

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  return (
    <>
      {<OverlayLoader isLoading={isLoading}></OverlayLoader>}
      <StyledEngineProvider injectFirst>
        <StyledAppBar position='sticky'>
          <Container className={classes.container} maxWidth={false}>
            <Box className={classes.OuterBox}>
              <Box className={classes.logoAlign}>
                <Typography
                  className={classes.FalconLogo}
                  noWrap
                  component='img'
                  src={getAppConfigValue(PRE_LOGIN_CONFIG_KEY_S3URL) + 'FalconLogoWhite.svg'}></Typography>
                <Typography
                  className={classes.FalconTitle}
                  noWrap
                  component='img'
                  src={getAppConfigValue(PRE_LOGIN_CONFIG_KEY_S3URL) + 'FalconeerNameWhite.svg'}></Typography>
              </Box>
              <Box className={classes.alignment}>
                <Typography className={classes.WelcomeTitle}>{currentUser?.tenantName}</Typography>
              </Box>
              <Box className={classes.alignment}>
                <Typography className={classes.WelcomeTitle}>
                  {`Welcome ${currentUser?.firstName} ${currentUser?.lastName} (${currentUser?.groups?.join(' ')})`}
                </Typography>
                <Box className={classes.alignment}>
                  <Typography className={classes.SignOutButton}>
                    <SignOutAction onClick={() => setOpen(true)} />
                  </Typography>
                  <CustomDialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>{falconeerConst.CONFIRMATION}</DialogTitle>
                    <DialogContent dividers>{falconeerConst.LOGOUT_MESSAGE}</DialogContent>
                    <DialogActions>
                      <Button onClick={handleClick}>{falconeerConst.LOGOUT}</Button>
                      <Button onClick={() => setOpen(false)}>{falconeerConst.CANCEL}</Button>
                    </DialogActions>
                  </CustomDialog>
                </Box>
              </Box>
            </Box>
            <MobileMenu
              isMenuOpen={Boolean(mobileMoreAnchorEl)}
              handleMenuOpen={handleMobileMenuOpen}
              handleMenuClose={handleMobileMenuClose}
              anchorEl={mobileMoreAnchorEl}
            />
            <DefaultMenu
              isMenuOpen={Boolean(anchorEl)}
              handleMenuClose={handleMenuClose}
              anchorEl={anchorEl}
              signOut={signOut}
            />
          </Container>
        </StyledAppBar>
      </StyledEngineProvider>
    </>
  );
};
export default AppHeader;
