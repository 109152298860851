import { useNavigate } from 'react-router-dom';
import { EntityManager } from '../../components/entity-manager/EntityManager';
import { ActionHandler } from '../../components/entity-manager/types';
import { TaskConfig } from './TaskConfig';
import { useState } from 'react';
import Button from '@mui/material/Button';
import React from 'react';
import { TextField, Box } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { StatusType } from '../../api/CoreAPI';
import { falconeerConst } from '../../utils/FalconeerConst';
import DialogContentText from '@mui/material/DialogContentText';
import { FSecondaryButton } from '../../components/fcomponents';
import { Patient } from '../../api/CoreAPI';
import patientService from '../../services/PatientService';
import FalconError, { E_PATIENT_DETAILS } from '../../falconeer/FalconErrors';
export const TaskDashboard = () => {
  const navigate = useNavigate();
  const [remarks, setRemarks] = useState('');
  const [open, setOpen] = React.useState(false);
  const [patientId, setPatientId] = useState<any>({});
  const [fullWidth] = React.useState<DialogProps['fullWidth']>(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRemarks('');
  };
  const navigatetoPage: ActionHandler = async (action: string, payload: any | null) => {
    const userInfo: Patient = await patientService.getOne({ id: payload }).catch(err => {
      throw new FalconError(E_PATIENT_DETAILS);
    });

    userInfo.user && sessionStorage.setItem('patientName', userInfo.user.email);

    navigate(`/patients/dashboard`, {
      state: userInfo,
      replace: true,
    });
  };

  const updateRemarks: ActionHandler = async (action: string, payload: any | null) => {
    setPatientId(payload);
  };

  const changeStatus = () => {
    let updatedData = { ...patientId };
    updatedData.remarks = remarks;
    updatedData.status = patientId.status === StatusType.Pending ? StatusType.Completed : StatusType.Pending;
    TaskConfig.actionHandler('update', updatedData);
    TaskConfig.refreshEntityData = new Date();
  };

  const taskActionHandler: ActionHandler = TaskConfig.actionHandler;

  const actionHandler: ActionHandler = async (action: string, payload: any | null) => {
    if (action == falconeerConst.SELECT) {
      navigatetoPage(action, payload);
    } else if (action == falconeerConst.UPDATE_REMARKS) {
      handleClick();
      updateRemarks(action, payload);
    } else {
      taskActionHandler(action, payload);
    }
  };

  const customIcon = () => {
    return (
      <>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          onClose={handleClose}
          BackdropProps={{ style: { opacity: 0.1 } }}
          className='popup-box-shadow'>
          <DialogTitle>Add Remarks</DialogTitle>
          <DialogContent>
            <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
              <Box
                component='form'
                sx={{
                  '& > :not(style)': { mb: 2, width: '60ch' },
                }}
                noValidate
                autoComplete='off'>
                <TextField
                  margin='dense'
                  id='name'
                  label='Remark'
                  type='email'
                  multiline
                  fullWidth
                  variant='standard'
                  onChange={e => {
                    setRemarks(e.target.value);
                  }}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                changeStatus();
                setRemarks('');
                handleClose();
              }}>
              {patientId.status === StatusType.Pending ? falconeerConst.markComplete : falconeerConst.markPending}
            </Button>
            <FSecondaryButton onClick={handleClose}>Cancel</FSecondaryButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  TaskConfig.actionHandler = actionHandler;
  TaskConfig.customIconHandler = [
    {
      customIcon: customIcon,
    },
  ];
  return (
    <>
      <EntityManager config={TaskConfig} />
    </>
  );
};
